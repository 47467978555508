<template>
  <div class="loaderDiv">
            <div class="flex items-center justify-center loader" v-if="this.$store.state.loadingStatus" ></div>
            <div class="flex items-center justify-center" v-if="this.$store.state.loadingStatus">{{ text }}</div>
          </div>
</template>

<script>
export default {
    props: {
        text: {
            required: false,
            type: String,
            default:"Loading..."
        }
    }
}
</script>

<style scoped>

.loaderDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loader {
    margin-bottom: 15px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #d4d2f9 #d4d2f9 transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #4f46e5 #4f46e5;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
}

.loader::before {
    width: 30px;
    height: 30px;
    border-color: #d4d2f9 #d4d2f9 transparent transparent;
    animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotationBack {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}

@media screen and (max-width:480px){
    .loader {
    width: 35px;
    height: 35px;
    }

.loader::after,
.loader::before {
    width: 25px;
    height: 25px;
    }

.loader::before {
    width: 15px;
    height: 15px;
    }

}
</style>