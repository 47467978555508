<template >
  <!-- v-if="isModalClose"  -->
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-show="isCardModalOpen">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <!--
        Background overlay, show/hide based on modal state.
      -->
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <!--
        Modal panel, show/hide based on modal state.
      -->
      <div  class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div>
            <div class="mt-3 text-center sm:mt-0 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                Prenos v živo preko RTMP
              </h3>
              <div class="mt-2">
                <form action="">
                  <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                    <div class="sm:col-span-6">
                      <label
                        for="rtmp-server"
                        class="block text-sm font-medium text-gray-700"
                      >
                        RTMP Server
                      </label>
                      <div class="mt-1">
                        <input
                          v-model="urlWithoutKey"
                          type="text"
                          name="rtmp-server"
                          id="rtmp-server"
                          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-gray-100"
                          readonly
                        />
                      </div>
                    </div>

                    <div class="sm:col-span-6">
                      <label
                        for="rtmp-stream-key"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Tvoj privatni ključ za prenos v živo
                      </label>
                      <div class="mt-1">
                        <input
                          v-model="key"
                          type="text"
                          name="rtmp-stream-key"
                          id="rtmp-stream-key"
                          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-gray-100"
                          readonly
                        />
                      </div>
                    </div>

                    <div class="sm:col-span-6">
                      <label
                        for="rtmp-full-url"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Celotna RTMP povezava
                      </label>
                      <div class="mt-1">
                        <input
                          v-model="fullUrl"
                          readonly
                          type="text"
                          name="rtmp-full-url"
                          id="rtmp-full-url"
                          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-gray-100"
                          />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="py-4 px-6 text-red">
          Navodila modal?
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button @click="closeModal" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Zapri
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store'
export default {
  name: "rtmpModal",

  methods: {
    closeModal() {
      this.$emit('onCloseModal', false)
    }
  },

  computed: {
    fullUrl() {
      let url = store.state.singleEvent.liveStream.rtmpUrl
      let fullUrl = url;
      return fullUrl;
    },

    urlWithoutKey() {
      return this.fullUrl.slice(0, -6);
    },

    key() {
      return this.fullUrl.slice(-6)
    }
  }
}
</script>
