<!-- v-if="authenicated" -->
<template>
<div>
  <div class="min-h-screen flex justify-center relative">
    <div class="absolute left-0 top-0 mx-auto w-full py-4">
      <div class="container px-4 mx-auto">
        <div class="flex justify-between">
          <button
            @click="backClick"
            type="button"
            class="inline-flex items-center px-2.5 py-1.5 text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg
              class="mr-1 -ml-0.5 h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Back
          </button>

          <button
            @click="logoutClick"
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Log out
          </button>
        </div>
      </div>
    </div>

    <div class="max-w-md w-full space-y-8 mt-24">
      <div>
        <!-- <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow"> -->
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">

          Pozdravljen/a {{ this.$store.state.login.name }} 

        </h2>
        <p class="mt-1 text-sm text-gray-500">
            Here you can change your profile data
        </p>
      </div>
      <div>
        <nav class="space-y-1 divide-y" aria-label="Sidebar">
          <router-link :to="{ name: 'EditAccount' }" class="text-indigo-400 hover:text-gray-900 flex items-center px-3 py-2 text-base font-medium">
            <span class="truncate">
              Edit profile
            </span>
          </router-link>

          <router-link :to="{ name: 'EditAccount' }" class="text-indigo-400 hover:text-gray-900 flex items-center px-3 py-2 text-base font-medium">
            <span class="truncate">
              Transaction history
            </span>
          </router-link>
        </nav>
      </div>
    </div>
  </div>
  
</div>
</template>

<script>
export default {
  name: "EditAccount",

  methods: {
    logoutClick() {
      sessionStorage.clear()
      this.$store.dispatch('fetchLogOut').then(() => this.$router.push('/'));
    },

    backClick() {
      this.$router.push("/list");
    }
  },

  mounted() {
    this.$store.dispatch("fetchEditAccount")
  }
}
</script>