<script>
export default {
    beforeCreate() {
        this.$store.dispatch("fetchRecoveryPassword", this.$route.params.code).then(() => {
            if (this.$route.params.code) {
                this.$store.state.password.success = "Your password was successfully reset. Please login."
                this.$router.push('/login')
            } else{
                this.$store.state.password.error = "Error. Please login again."
                this.$router.push('/login')
            }
        })
    }
}
</script>