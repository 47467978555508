import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Register from "../views/Register.vue";
import SignIn from "../views/SignIn.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import List from "../views/List.vue";
import Create from "../views/Create.vue";
import Edit from "../views/Edit.vue";
import Event from "../views/Event.vue";
import Account from "../views/Account.vue";
import EditAccount from "../views/Account/EditAccount.vue";
import ConfirmPassword from '../views/ConfirmPassword.vue';
import PasswordRecovery from '../views/PasswordRecovery.vue'
import store from '../store'
import ConfirmRegistration from '../views/ConfirmRegistration.vue'

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: '/confirm/:code',
    name: 'confirmRegistartion',
    component: ConfirmRegistration
  },
  {
    path: '/confirm/:code',
    name: 'confirmpassword',
    component: ConfirmPassword
  },
  {
    path: '/passwordRecovery/:code',
    name: 'Passwordrecovery',
    component: PasswordRecovery
  },
  {
    path: "/account",
    name: "Account",
    component: Account
  },
  {
    path: "/account/edit",
    name: "EditAccount",
    component: EditAccount,
  },
  {
    path: "/list",
    name: "List",
    component: List,
    meta: { requiresAuth: true },
  },
  {
    path: "/create",
    name: "Create",
    component: Create
  },
  {
    path: "/edit/:id",
    name: "Edit",
    component: Edit
  },
  {
    path: "/event/:id/:slug",
    name: "Event",
    component: Event
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isUserLoggedIn = store.getters.isAuthenticated
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isUserLoggedIn) {
      store.dispatch('fetchLogOut')
      next({
        path: '/sign-in',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})



export default router;
