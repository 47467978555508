<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-show="isCardModalOpen">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <!--
        Background overlay, show/hide based on modal state.
      -->
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <!--
        Modal panel, show/hide based on modal state.
      -->
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                Plačilo s kreditno kartico
              </h3>
              <div class="mt-2">
                <form action="">
                  <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div class="sm:col-span-6">
                      <label
                        for="user-email"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <div class="mt-1">
                        <input
                          v-model="mutableUser.email"
                          type="email"
                          name="user-email"
                          id="user-email"
                          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div class="sm:col-span-6">
                      <label
                        for="user-card"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Informacije kartice - tuki pride stripe forma?
                      </label>
                      <div class="mt-1">
                        <input
                          v-model="mutableUser.cardNumber"
                          type="email"
                          name="user-email"
                          id="user-email"
                          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div class="sm:col-span-6">
                      <label
                        for="user-card-name"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Ime na kartici
                      </label>
                      <div class="mt-1">
                        <input
                          v-model="mutableUser.cardName"
                          type="text"
                          name="user-card-name"
                          id="user-card-name"
                          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div class="sm:col-span-6">
                      <label
                        for="user-card-address"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Naslov
                      </label>
                      <div class="mt-1">
                        <select id="location" name="location" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-t-md"
                          v-model="mutableUser.cardAddressCountry">
                          <option value="Slovenia">Slovenia</option>
                          <option value="USA">USA</option>
                        </select>
                      </div>
                      <div>
                        <input
                          v-model="mutableUser.cardAddressStreet1"
                          type="text"
                          name="user-card-address-street-1"
                          id="user-card-address-street-1"
                          placeholder="Naslov 1"
                          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300"
                        />
                      </div>
                      <div>
                        <input
                          v-model="mutableUser.cardAddressStreet2"
                          type="text"
                          name="user-card-address-street-2"
                          id="user-card-address-street-2"
                          placeholder="Naslov 2"
                          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300"
                        />
                      </div>
                      <div class="grid grid-cols-2">
                        <div>
                          <input
                            v-model="mutableUser.cardPostalCode"
                            type="number"
                            name="user-card-postal-code"
                            id="user-card-postal-code"
                            placeholder="Poštna št."
                            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-bl-md"
                          />
                        </div>
                        <div>
                          <input
                            v-model="mutableUser.cardCity"
                            type="text"
                            name="user-card-city"
                            id="user-card-city"
                            placeholder="Kraj"
                            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-br-md"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="sm:col-span-6">
                      <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                          <input id="user-card-is-business" name="user-card-is-business" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            v-model="mutableUser.cardIsBusiness">
                        </div>
                        <div class="ml-3 text-sm">
                          <label for="user-card-is-business" class="font-medium text-gray-700">Nakup kot podjetje</label>
                        </div>
                      </div>

                      <div class="mt-4" v-if="mutableUser.cardIsBusiness">
                        <label
                          for="user-card-name"
                          class="block text-sm font-medium text-gray-700"
                        >
                          VAT Informacije
                        </label>
                        <div class="mt-1">
                          <input
                            v-model="mutableUser.cardVATBusinessName"
                            type="text"
                            name="user-card-vat-business-name"
                            id="user-card-vat-business-name"
                            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-t-md"
                            placeholder="Ime podjetja"
                          />
                        </div>
                        <div>
                          <input
                            v-model="mutableUser.cardVATBusinessNumber"
                            type="text"
                            name="user-card-vat-business-number"
                            id="user-card-vat-business-number"
                            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-b-md"
                            placeholder="Davčna številka"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-white bg-indigo-600 hover:bg-indigo-700 sm:ml-3 sm:w-auto sm:text-sm">
            Plačaj
          </button>
          <button @click="closeModal" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Zapri
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "cardModal",
  
  props: ['user'],

  setup(props, { emit }) {
    const closeModal = () => {
      emit('onCloseModal', false)
    }

    const mutableUser = reactive(props.user)

    return {
      closeModal,
      mutableUser
    }
  },
}
</script>
