<script>
export default {
    beforeCreate() {
        this.$store.dispatch("fetchRegisterUser", this.$route.params.code).then(() => {
            if (this.$route.params.code) {
                this.$store.state.register.success = "Your registration was successfully. Please login."
                this.$router.push('/login')
            } else {
                this.$store.register.error = "Error. Please enter correct data."
                this.$router.push('/register')
            }
        })
    }
}
</script>