<template>

<div>
  <div class="min-h-screen flex justify-center relative">
    <div class="absolute left-0 top-0 mx-auto w-full py-4">
      <div class="container px-4 mx-auto">
        <div class="flex justify-between">
          <button
            @click="backClick"
            type="button"
            class="inline-flex items-center px-2.5 py-1.5 text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg
              class="mr-1 -ml-0.5 h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Back
          </button>
        </div>
      </div>
    </div>

    <div class="max-w-md w-full space-y-8 mt-24">
      <div>
        <!-- <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow"> -->
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Edit account
        </h2>
      </div>
      <Form class="mt-8 space-y-6" action="#" method="POST">
        <input type="hidden" name="remember" value="true">
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="user-firstname" class="sr-only">Name</label>
            <Field id="user-firstname" :rules="validateName" name="user-firstname" type="text" autocomplete="given-name" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Name"
              v-model="name" />
          </div>
          <div>
            <label for="user-lastname" class="sr-only">Lastname</label>
            <Field id="user-lastname" :rules="validateName" name="user-lastname" type="text" autocomplete="family-name" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Lastname"
              v-model="surname" />
              <!-- <ErrorMessage class="warning" name="user-lastname" /> -->
          </div>
          <div>
            <label for="email-address" class="sr-only">E-mail</label>
            <Field id="email-address" :rules="validateEmail" name="email" type="email" autocomplete="email" required class="appearance-none rounded-none rounded-b-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="E-mail"
              v-model="email" />
              <ErrorMessage class="warning" name="email" />
          </div>
          <!-- <div>
            <label for="password" class="sr-only">Geslo</label>
            <input id="password" name="password" type="password" autocomplete="new-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Geslo">
          </div>
          <div>
            <label for="password-confirmation" class="sr-only">Potrditev gesla</label>
            <input id="password-confirmation" name="password-confirmation" type="password" autocomplete="new-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Potrditev gesla">
          </div> -->
        </div>

        <div>
          <button type="submit" @click="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Update
          </button>
        </div>
      </Form>
    </div>
  </div>
  
</div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';

export default {
  name: "EditAccount",

  components: {
    Form,
    Field,
    ErrorMessage
  },

  computed: {
    name: {
      get() {
        return this.$store.state.login.name
      },

      set(value) {
        this.$store.commit('loginName', value)
      }
    },

    surname: {
      get() {
        return this.$store.state.login.surname
      },

      set(value) {
        this.$store.commit('loginSurname', value)
      }
    },

    email: {
      get() {
        return this.$store.state.login.email
      },

      set(value) {
        this.$store.commit('loginEmail', value)
      }
    },
  },

  methods: {
    validateName(value) {
      if (!value) {
        return 'This filed is required'
      }
    },

    validateEmail(value) {
      if (!value) {
        return 'This filed is required'
      }

      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,5}$/i;

      if (!regex.test(value)) {
        return 'This field must be a valid email';
      }

      return true;
    },

    submit() {
      this.$store.dispatch("fetchEditAccount").then(()=> this.$router.push("/account"))
    },

    backClick() {
      this.$router.back();
    }
  }
}
</script>

<style scoped>
.warning{
    color: red;
  }

</style>