<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div >
      <div >
        <!-- :value=this.$store.state.login.username -->
        <input v-model="username"   />
      </div>
      <div>
        <div v-for="message in messages" :key="message">
          <div class="d-flex w-100 align-items-center justify-content-between">
            <strong class="mb-1">{{ message.username }}</strong>
          </div>
          <div>{{ message.message }}</div>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit">
      <input placeholder="Write a message" v-model="message" />
    </form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import Pusher from 'pusher-js';
export default {
  name: 'App',
  setup() {
    const username = ref('');
    const messages = ref([]);
    const message = ref('');
    onMounted(() => {
      Pusher.logToConsole = true;
      const pusher = new Pusher('9b7de64f04281789aff4', {
        cluster: 'eu'
      });
      const channel = pusher.subscribe('my-channel');
      channel.bind('my-event', data => {
        messages.value.push(data);
      });
    })
    const submit = async () => {
        const token = window.localStorage.getItem("apollo-token");
        await fetch("http://localhost:8080/api/messages", {
          method: "POST",
            headers: {
              "x-portal": process.env.VUE_APP_XPORTAL,
              "Authorization": `Bearer + ${token}`,
              "X-Requested-With": "XMLHttpRequest",
              "Content-Type": "application/json",
            },
              body: JSON.stringify({
                username: username.value,
                message: message.value
            }),
        });
      message.value = ''; 
    }

    return {
      username,
      messages,
      message,
      submit
    }
  }
}
</script>

<style scoped>
.scrollArea {
  min-height: 500px;
  /* background-color: transparent !important; */
}

/* .inputMessage {
  width: 100%;
} */
</style>