<template>
  <div class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-50">
    <div class="max-w-md w-full space-y-8">
      <div>
        <!-- <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow"> -->
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Register a new account
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          or
          <router-link :to="{ name: 'SignIn' }" class="font-medium text-indigo-600 hover:text-indigo-500">
            sign in if you already have an account
          </router-link>
        </p>
      </div>
      <Form class="mt-8 space-y-6" action="#" method="POST">
        <Field type="hidden" name="remember" value="true" v-model="remember"/>
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="user-firstname" class="sr-only">Name</label>
            <Field id="user-firstname" v-model="name" name="name" type="text" autocomplete="given-name" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Name" />
             <ErrorMessage class="warning" name="email" />
              <div class="warning" v-if="this.$store.state.registerUser.error">Error: {{ this.$store.state.registerUser.error }}</div>
          </div>
          <div>
            <label for="user-lastname" class="sr-only">Surname</label>
            <Field id="user-lastname" v-model="surname" name="surname" type="text" autocomplete="family-name" required 
            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Surname" />
             <ErrorMessage class="warning" name="email" />
             <div class="warning" v-if="this.$store.state.registerUser.error">Error: {{ this.$store.state.registerUser.error }}</div>
          </div>
          <div>
            <label for="email-address" class="sr-only">E-email</label>
            <Field id="email-address" v-model="email" name="email" :rules="validateEmail" type="email" autocomplete="email" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="E-pošta" />
          <ErrorMessage class="warning" name="email" />
               <div class="warning" v-if="this.$store.state.registerUser.error">Error: {{ this.$store.state.registerUser.error }}</div>
          </div>
          <div>
            <label for="password" class="sr-only">Password</label>
            <Field id="password" v-model="password" :rules="validatePassword" name="password" type="password" autocomplete="new-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password" />
           <ErrorMessage class="warning" name="email" />
               <div class="warning" v-if="this.$store.state.registerUser.error">Error: {{ this.$store.state.registerUser.error }}</div>
          </div>
          <div>
            <label for="password-confirmation" class="sr-only">Confirm password</label>
            <Field id="password-confirmation" v-model="passwordConfirmation" :rules="validatePassword" name="password-confirmation" type="password" autocomplete="new-password" required 
            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Confirm password" />
           <ErrorMessage class="warning" name="email" />
               <div class="warning" v-if="this.$store.state.registerUser.error">Error: {{ this.$store.state.registerUser.error }}</div>
          </div>

          <!-- <div>
            <label for="phone-country" class="sr-only">Phone Country</label>
            <Field id="phone-country" v-model="phoneCountry" :rules="validRequired" name="phone-country" type="text" autocomplete="new-password" required 
            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Phone Country" />
           <ErrorMessage class="warning" name="email" />
               <div class="warning" v-if="this.$store.state.registerUser.error">Error: {{ this.$store.state.registerUser.error }}</div>
          </div> -->

        <!-- <label for="phone-country" class="sr-only">Phone Country</label>  -->
       <select name="phone-country"
          class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
            v-model="phoneCountry">
                <option v-for="country in options" :key="country.value" :value="country.value">
                  {{ country.label }}
                </option>
        </select>

          <div>
            <label for="phone" class="sr-only">Phone</label>
            <Field id="phone" v-model="phone" name="phone" :rules="validRequired" type="text" autocomplete="new-password" required 
            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Phone number" />
           <ErrorMessage class="warning" name="email" />
               <div class="warning" v-if="this.$store.state.registerUser.error">Error: {{ this.$store.state.registerUser.error }}</div>
          </div><br/>
        
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <Field id="newsletter" v-model="newsletter" name="newsletter" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
              <label for="newsletter" class="ml-2 block text-sm text-gray-900">
                Newsletter
              </label>
            </div>
          </div><br/>
        
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <Field id="remember-me" v-model="rememberMe" name="rememberMe" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
            <label for="remember-me" class="ml-2 block text-sm text-gray-900">
              Remember me
            </label>
          </div>

          <div class="text-sm">
            <router-link :to="{ name: 'ForgotPassword' }" class="font-medium text-indigo-600 hover:text-indigo-500">
              Pozabljeno geslo
            </router-link>
          </div>
        </div><br/>

        <div>
          <button type="submit" @click.prevent="registertForm" :disabled="!disableButton" class="disabled group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
              </svg>
            </span>
            Register
          </button>
        </div>
        </div>
        <!-- <div class="flex items-center justify-center" v-if="this.$store.state.graphqlError">{{ this.$store.state.graphqlError }}</div> -->
      <div class="flex items-center justify-center" v-if="er">{{ er }}</div>
      </Form>
    <Loader />
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import Loader from '../components/Loader.vue';



export default {
  name: "Register",

  components: {
    Form,
    Field,
    ErrorMessage,
    Loader
  },

  data() {
    return {
      er: "",
      options: [
        { value: 'AF', label: 'Afghanistan' },
        { value: 'AX', label: 'Aland Islands' },
        { value: 'AL', label: 'Albania' },
        { value: 'DZ', label: 'Algeria' },
        { value: 'AS', label: 'American Samoa' },
        { value: 'AD', label: 'Andorra' },
        { value: 'AO', label: 'Angola' },
        { value: 'AI', label: 'Anguilla' },
        { value: 'AQ', label: 'Antarctica' },
        { value: 'AG', label: 'Antigua And Barbuda' },
        { value: 'AR', label: 'Argentina' },
        { value: 'AM', label: 'Armenia' },
        { value: 'AW', label: 'Aruba' },
        { value: 'AU', label: 'Australia' },
        { value: 'AT', label: 'Austria' },
        { value: 'AZ', label: 'Azerbaijan' },
        { value: 'BS', label: 'Bahamas' },
        { value: 'BH', label: 'Bahrain' },
        { value: 'BD', label: 'Bangladesh' },
        { value: 'BB', label: 'Barbados' },
        { value: 'BY', label: 'Belarus' },
        { value: 'BE', label: 'Belgium' },
        { value: 'BZ', label: 'Belize' },
        { value: 'BJ', label: 'Benin' },
        { value: 'BM', label: 'Bermuda' },
        { value: 'BT', label: 'Bhutan' },
        { value: 'BO', label: 'Bolivia' },
        { value: 'BA', label: 'Bosnia And Herzegovina' },
        { value: 'BW', label: 'Botswana' },
        { value: 'BV', label: 'Bouvet Island' },
        { value: 'BR', label: 'Brazil' },
        { value: 'IO', label: 'British Indian Ocean Territory' },
        { value: 'BN', label: 'Brunei Darussalam' },
        { value: 'BG', label: 'Bulgaria' },
        { value: 'BF', label: 'Burkina Faso' },
        { value: 'BI', label: 'Burundi' },
        { value: 'KH', label: 'Cambodia' },
        { value: 'CM', label: 'Cameroon' },
        { value: 'CA', label: 'Canada' },
        { value: 'CV', label: 'Cape Verde' },
        { value: 'KY', label: 'Cayman Islands' },
        { value: 'CF', label: 'Central African Republic' },
        { value: 'TD', label: 'Chad' },
        { value: 'CL', label: 'Chile' },
        { value: 'CN', label: 'China' },
        { value: 'CX', label: 'Christmas Island' },
        { value: 'CC', label: 'Cocos (Keeling) Islands' },
        { value: 'CO', label: 'Colombia' },
        { value: 'KM', label: 'Comoros' },
        { value: 'CG', label: 'Congo' },
        { value: 'CD', label: 'Congo}, Democratic Republic' },
        { value: 'CK', label: 'Cook Islands' },
        { value: 'CR', label: 'Costa Rica' },
        { value: 'CI', label: 'Cote D\'Ivoire' },
        { value: 'HR', label: 'Croatia' },
        { value: 'CU', label: 'Cuba' },
        { value: 'CY', label: 'Cyprus' },
        { value: 'CZ', label: 'Czech Republic' },
        { value: 'DK', label: 'Denmark' },
        { value: 'DJ', label: 'Djibouti' },
        { value: 'DM', label: 'Dominica' },
        { value: 'DO', label: 'Dominican Republic' },
        { value: 'EC', label: 'Ecuador' },
        { value: 'EG', label: 'Egypt' },
        { value: 'SV', label: 'El Salvador' },
        { value: 'GQ', label: 'Equatorial Guinea' },
        { value: 'ER', label: 'Eritrea' },
        { value: 'EE', label: 'Estonia' },
        { value: 'ET', label: 'Ethiopia' },
        { value: 'FK', label: 'Falkland Islands (Malvinas)' },
        { value: 'FO', label: 'Faroe Islands' },
        { value: 'FJ', label: 'Fiji' },
        { value: 'FI', label: 'Finland' },
        { value: 'FR', label: 'France' },
        { value: 'GF', label: 'French Guiana' },
        { value: 'PF', label: 'French Polynesia' },
        { value: 'TF', label: 'French Southern Territories' },
        { value: 'GA', label: 'Gabon' },
        { value: 'GM', label: 'Gambia' },
        { value: 'GE', label: 'Georgia' },
        { value: 'DE', label: 'Germany' },
        { value: 'GH', label: 'Ghana' },
        { value: 'GI', label: 'Gibraltar' },
        { value: 'GR', label: 'Greece' },
        { value: 'GL', label: 'Greenland' },
        { value: 'GD', label: 'Grenada' },
        { value: 'GP', label: 'Guadeloupe' },
        { value: 'GU', label: 'Guam' },
        { value: 'GT', label: 'Guatemala' },
        { value: 'GG', label: 'Guernsey' },
        { value: 'GN', label: 'Guinea' },
        { value: 'GW', label: 'Guinea-Bissau' },
        { value: 'GY', label: 'Guyana' },
        { value: 'HT', label: 'Haiti' },
        { value: 'HM', label: 'Heard Island & Mcdonald Islands' },
        { value: 'VA', label: 'Holy See (Vatican City State)' },
        { value: 'HN', label: 'Honduras' },
        { value: 'HK', label: 'Hong Kong' },
        { value: 'HU', label: 'Hungary' },
        { value: 'IS', label: 'Iceland' },
        { value: 'IN', label: 'India' },
        { value: 'ID', label: 'Indonesia' },
        { value: 'IR', label: 'Iran}, Islamic Republic Of' },
        { value: 'IQ', label: 'Iraq' },
        { value: 'IE', label: 'Ireland' },
        { value: 'IM', label: 'Isle Of Man' },
        { value: 'IL', label: 'Israel' },
        { value: 'IT', label: 'Italy' },
        { value: 'JM', label: 'Jamaica' },
        { value: 'JP', label: 'Japan' },
        { value: 'JE', label: 'Jersey' },
        { value: 'JO', label: 'Jordan' },
        { value: 'KZ', label: 'Kazakhstan' },
        { value: 'KE', label: 'Kenya' },
        { value: 'KI', label: 'Kiribati' },
        { value: 'KR', label: 'Korea' },
        { value: 'KW', label: 'Kuwait' },
        { value: 'KG', label: 'Kyrgyzstan' },
        { value: 'LA', label: 'Lao People\'s Democratic Republic' },
        { value: 'LV', label: 'Latvia' },
        { value: 'LB', label: 'Lebanon' },
        { value: 'LS', label: 'Lesotho' },
        { value: 'LR', label: 'Liberia' },
        { value: 'LY', label: 'Libyan Arab Jamahiriya' },
        { value: 'LI', label: 'Liechtenstein' },
        { value: 'LT', label: 'Lithuania' },
        { value: 'LU', label: 'Luxembourg' },
        { value: 'MO', label: 'Macao' },
        { value: 'MK', label: 'Macedonia' },
        { value: 'MG', label: 'Madagascar' },
        { value: 'MW', label: 'Malawi' },
        { value: 'MY', label: 'Malaysia' },
        { value: 'MV', label: 'Maldives' },
        { value: 'ML', label: 'Mali' },
        { value: 'MT', label: 'Malta' },
        { value: 'MH', label: 'Marshall Islands' },
        { value: 'MQ', label: 'Martinique' },
        { value: 'MR', label: 'Mauritania' },
        { value: 'MU', label: 'Mauritius' },
        { value: 'YT', label: 'Mayotte' },
        { value: 'MX', label: 'Mexico' },
        { value: 'FM', label: 'Micronesia}, Federated States Of' },
        { value: 'MD', label: 'Moldova' },
        { value: 'MC', label: 'Monaco' },
        { value: 'MN', label: 'Mongolia' },
        { value: 'ME', label: 'Montenegro' },
        { value: 'MS', label: 'Montserrat' },
        { value: 'MA', label: 'Morocco' },
        { value: 'MZ', label: 'Mozambique' },
        { value: 'MM', label: 'Myanmar' },
        { value: 'NA', label: 'Namibia' },
        { value: 'NR', label: 'Nauru' },
        { value: 'NP', label: 'Nepal' },
        { value: 'NL', label: 'Netherlands' },
        { value: 'AN', label: 'Netherlands Antilles' },
        { value: 'NC', label: 'New Caledonia' },
        { value: 'NZ', label: 'New Zealand' },
        { value: 'NI', label: 'Nicaragua' },
        { value: 'NE', label: 'Niger' },
        { value: 'NG', label: 'Nigeria' },
        { value: 'NU', label: 'Niue' },
        { value: 'NF', label: 'Norfolk Island' },
        { value: 'MP', label: 'Northern Mariana Islands' },
        { value: 'NO', label: 'Norway' },
        { value: 'OM', label: 'Oman' },
        { value: 'PK', label: 'Pakistan' },
        { value: 'PW', label: 'Palau' },
        { value: 'PS', label: 'Palestinian Territory}, Occupied' },
        { value: 'PA', label: 'Panama' },
        { value: 'PG', label: 'Papua New Guinea' },
        { value: 'PY', label: 'Paraguay' },
        { value: 'PE', label: 'Peru' },
        { value: 'PH', label: 'Philippines' },
        { value: 'PN', label: 'Pitcairn' },
        { value: 'PL', label: 'Poland' },
        { value: 'PT', label: 'Portugal' },
        { value: 'PR', label: 'Puerto Rico' },
        { value: 'QA', label: 'Qatar' },
        { value: 'RE', label: 'Reunion' },
        { value: 'RO', label: 'Romania' },
        { value: 'RU', label: 'Russian Federation' },
        { value: 'RW', label: 'Rwanda' },
        { value: 'BL', label: 'Saint Barthelemy' },
        { value: 'SH', label: 'Saint Helena' },
        { value: 'KN', label: 'Saint Kitts And Nevis' },
        { value: 'LC', label: 'Saint Lucia' },
        { value: 'MF', label: 'Saint Martin' },
        { value: 'PM', label: 'Saint Pierre And Miquelon' },
        { value: 'VC', label: 'Saint Vincent And Grenadines' },
        { value: 'WS', label: 'Samoa' },
        { value: 'SM', label: 'San Marino' },
        { value: 'ST', label: 'Sao Tome And Principe' },
        { value: 'SA', label: 'Saudi Arabia' },
        { value: 'SN', label: 'Senegal' },
        { value: 'RS', label: 'Serbia' },
        { value: 'SC', label: 'Seychelles' },
        { value: 'SL', label: 'Sierra Leone' },
        { value: 'SG', label: 'Singapore' },
        { value: 'SK', label: 'Slovakia' },
        { value: 'SI', label: 'Slovenia' },
        { value: 'SB', label: 'Solomon Islands' },
        { value: 'SO', label: 'Somalia' },
        { value: 'ZA', label: 'South Africa' },
        { value: 'GS', label: 'South Georgia And Sandwich Isl.' },
        { value: 'ES', label: 'Spain' },
        { value: 'LK', label: 'Sri Lanka' },
        { value: 'SD', label: 'Sudan' },
        { value: 'SR', label: 'Suriname' },
        { value: 'SJ', label: 'Svalbard And Jan Mayen' },
        { value: 'SZ', label: 'Swaziland' },
        { value: 'SE', label: 'Sweden' },
        { value: 'CH', label: 'Switzerland' },
        { value: 'SY', label: 'Syrian Arab Republic' },
        { value: 'TW', label: 'Taiwan' },
        { value: 'TJ', label: 'Tajikistan' },
        { value: 'TZ', label: 'Tanzania' },
        { value: 'TH', label: 'Thailand' },
        { value: 'TL', label: 'Timor-Leste' },
        { value: 'TG', label: 'Togo' },
        { value: 'TK', label: 'Tokelau' },
        { value: 'TO', label: 'Tonga' },
        { value: 'TT', label: 'Trinidad And Tobago' },
        { value: 'TN', label: 'Tunisia' },
        { value: 'TR', label: 'Turkey' },
        { value: 'TM', label: 'Turkmenistan' },
        { value: 'TC', label: 'Turks And Caicos Islands' },
        { value: 'TV', label: 'Tuvalu' },
        { value: 'UG', label: 'Uganda' },
        { value: 'UA', label: 'Ukraine' },
        { value: 'AE', label: 'United Arab Emirates' },
        { value: 'GB', label: 'United Kingdom' },
        { value: 'US', label: 'United States' },
        { value: 'UM', label: 'United States Outlying Islands' },
        { value: 'UY', label: 'Uruguay' },
        { value: 'UZ', label: 'Uzbekistan' },
        { value: 'VU', label: 'Vanuatu' },
        { value: 'VE', label: 'Venezuela' },
        { value: 'VN', label: 'Viet Nam' },
        { value: 'VG', label: 'Virgin Islands}, British' },
        { value: 'VI', label: 'Virgin Islands}, U.S.' },
        { value: 'WF', label: 'Wallis And Futuna' },
        { value: 'EH', label: 'Western Sahara' },
        { value: 'YE', label: 'Yemen' },
        { value: 'ZM', label: 'Zambia' },
        { value: 'ZW', label: 'Zimbabwe' }
      ]
    }
  },

  methods: {
      registertForm() {
        this.$store.dispatch("fetchRegisterUser")
          .then(() => this.$router.push('/login'))
          .then(() => this.$store.commit("loadingStatus", false))
      },

    //   registertForm() {
    //   if (this.$store.state.graphqlError) {
    //     this.er = "Validation is not valid. Please correct the wrong fields."
    //     return
    //   } else {
    //     this.$store.dispatch("fetchRegisterUser")
    //       .then(() => this.$router.push('/login'))
    //       .then(() => this.$store.commit("loadingStatus", false))
    //   }
    // },

      validRequired(value) {
        if (!value) {
          return 'This filed is required'
        }

        return true;
      }, 

      validateEmail(value) {
        if (!value) {
          return 'This filed is required'
        }

        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,5}$/i;

        if (!regex.test(value)) {
          return 'This field must be a valid email';
        }

        return true;
      },

      validatePassword(value) {
        if (!value) {
          return 'This filed is required'
        }

        const regex = /"^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"/;
        if (!regex.test(value)) {
          return 'Password must has minimum eight characters, at least one letter and one number';
        }

        return true;
      }
    },

  computed: {
    // binding inputs with v-model in Form
    email: {
      get() {
        return this.$store.state.registerUser.email
      },

      set(value) {
        this.$store.commit('updateRegisterUserEmail', value)
      }
    },

    password: {
      get() {
        return this.$store.state.registerUser.password
      },
      // triger od input fileda (kadarkoli se spremeni)
      set(value) {
        this.$store.commit('updateRegisterUserPassword', value)
      }
     },

    name: {
      get() {
        return this.$store.state.registerUser.name
      },
      // triger od input fileda (kadarkoli se spremeni)
      set(value) {
        this.$store.commit('updateRegisterUserName', value)
      }
     },

    surname: {
      get() {
        return this.$store.state.registerUser.surname
      },
      // triger od input fileda (kadarkoli se spremeni)
      set(value) {
        this.$store.commit('updateRegisterUserSurname', value)
      }
     },

    passwordConfirmation:{
      get() {
        return this.$store.state.registerUser.passwordConfirmation
      },
      // triger od input fileda (kadarkoli se spremeni)
      set(value) {
        this.$store.commit('updateRegisterUserpasswordConfirmation', value)
      }
     },

    phone: {
      get() {
        return this.$store.state.registerUser.phone
      },
      // triger od input fileda (kadarkoli se spremeni)
      set(value) {
        console.log(value)
        this.$store.commit('updateRegisterUserPhone', value)
      }
     },

    phoneCountry: {
      get() {
        return this.$store.state.registerUser.phoneCountry
      },
      // triger od input fileda (kadarkoli se spremeni)
      set(value) {
        this.$store.commit('updateRegisterUserPhoneCountry', value)
      }
    },
    
    newsletter: {
      get() {
        return this.$store.state.registerUser.newsletter
      },
      // triger od input fileda (kadarkoli se spremeni)
      set(value) {
        this.$store.commit('updateRegisterUserNewsletters', value)
      }
    },

     disableButton() {
      return this.$store.state.registerUser.name != '' && this.$store.state.registerUser.surname != '' && this.$store.state.registerUser.email != '' && this.$store.state.registerUser.password != '' && this.$store.state.registerUser.passwordConfirmation != '' && this.$store.state.registerUser.phone != '' && this.$store.state.registerUser.phoneCountry != ''
    }
   }
}

</script>

<style scoped>
 .warning{
    color: red;
  }

  .disabled:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
</style>