const eventTypes = [
  {
    text: 'Wedding',
    value: 'wedding'
  },
  {
    text: 'Baptism',
    value: 'baptism'
  },
  {
    text: 'Birthday',
    value: 'birthday'
  },
  {
    text: 'Funeral',
    value: 'funeral'
  },
  {
    text: 'Sport',
    value: 'sport'
  },
  {
    text: 'Other',
    value: 'other'
  }
]
  
export default eventTypes