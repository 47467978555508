<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> |
    <router-link to="/list">List</router-link>
  </div> -->
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
export default {
  async created() {
    let token = localStorage.getItem("apollo-token");
    if (token) {
      await this.$store.dispatch("fetchAuthUser");
    }
  }
}
</script>