import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { QuillEditor } from '@vueup/vue-quill';
import * as apolloProvider from "../../events.live/src/utils/graphql";
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";


const app = createApp(App);
app.use(apolloProvider.provider)
app.use(store);
app.use(router);
app.component('QuillEditor', QuillEditor);
app.use(VueAwesomePaginate);
app.mount("#app");
