import { createStore } from "vuex";
import gql from 'graphql-tag';
import { provider } from "../utils/graphql";
import moment from 'moment';
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],

  state: {
    singleEvent: {
      id: "",
      name: "",
      description: "",
      location: "",
      type: "",
      layout: "",
      chat: false,
      mainImage: {
        uri: ""
      },
      liveStream: {
        startsAt: new Date(),
        endsAt: new Date(),
        rtmpUrl: "",
        url: ""
      },
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone.toString(),
    },

    events: [],

    confirmRegistration: {
      code: "",
      password: "",
      portalId: ""
    },

    // password recovery check
    passwordRecoveryCheck: {
      code: "",
      portalId: ""
    },

    registerUser: {
      name: "",
      surname: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      phone: "",
      phoneCountry: "",
      newsletter: false,
      error: null
    },

    forgetEmail: {
      email: ""
    },

    // recovery email
    recoveryEmail: {
      email: ""
    },

    login: {
      email: "",
      password: "",
      error: null,
      name: "",
      surname: ""
    },

    loadingStatus: false,

    //token: null,
    token: localStorage.getItem('apollo-token') || "",
    userToken: {},
    authStatus: false,

    // confirm password
    password: {
      success: "",
      error: ""
    },

    // registration
    register: {
      success: "",
      error: ""
    },

    graphqlError: null,

    // create event
    createEvent: {
      name: "",
      description: "",
      startsAt: new Date(),
      endsAt: new Date(),
      location: "",
      type: "",
      layout: "test",
      chat: false,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone.toString(),
      mainImage: {
        uri: "",
        id: ""
      },
    },

    // edit event
    editEvent: {
      id: "",
      mainImage: {
        uri: "",
        id: ""
      },
      name: "",
      description: "",
      startsAt: new Date(),
      endsAt: new Date(),
      location: "",
      type: "",
      layout: "",
      chat: false,
      logo: {
        id:null,
        uri:""
      },
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone.toString()
    },

    editAccount: {
      id: "",
      name: "",
      surname: "",
      email: ""
    },

    // radio buttons
    security: "",
    securityPassword: "",
    inviteOnly: "",

    // pagination
    limit: 8,
    page: 1,
    total: 0,

    // registration success
    registrationSuccess: "",
    
    // registration fail
    registrationFail:""
  },

  mutations: {
    addEvent(state, event) {
      state.events.push(event);
    },

    deleteEvent(state, eventId) {
      // event.slug === eventId
      const index = state.events.findIndex((event) => event.id === eventId);
      state.events.splice(index, 1);
    },

    // getEvent(state, eventSlug) {
    //   const event = state.events.find((event) => event.id === eventSlug);
    //   state.singleEvent = event;
    // },

    loadingStatus(state, loadingStatus) {
      state.loadingStatus = loadingStatus
    },

    singleEvent(state, singleEvent) {
      state.singleEvent = singleEvent
    },

    // editEvent(state, eventObject) {
    //   const index = state.events.findIndex((event) => event.slug === eventObject.slug);
    //   state.events[index] = eventObject
    // },

    allEvents(state, allEvents) {
      state.events = allEvents;
    },

    setUser(state, user) {
      state.user = user;
    },

    updateEmailLogin(state, email) {
      state.login.email = email;
    },

    updatePasswordLogin(state, password) {
      state.login.password = password;
    },

    updateErrorLogin(state, error) {
      state.login.error = error;
    },

    // Register
    updateRegisterUserEmail(state, email) {
      state.registerUser.email = email;
    },

    updateRegisterUserName(state, name) {
      state.registerUser.name = name;
    },

    updateRegisterUserPhoneCountry(state, phoneCountry) {
      state.registerUser.phoneCountry = phoneCountry;
    },

    updateRegisterUserPhone(state, phone) {
      state.registerUser.phone = phone;
    },

    updateRegisterUserSurname(state, surname) {
      state.registerUser.surname = surname;
    },

    updateRegisterUserNewsletters(state, rememberMe) {
      state.registerUser.newsletter = rememberMe
    },

    updateRegisterRememberMe(state) {
      state.registerUser.rememberMe = !state.registerUser.rememberMe
    },

    updateRegisterUserpasswordConfirmation(state, passwordConfirmation) {
      state.registerUser.passwordConfirmation = passwordConfirmation;
    },
  
    updateRegisterUserPassword(state, password) {
      state.registerUser.password = password;
    },

    // forget email
    updateForgetEmail(state, email) {
      state.forgetEmail.email = email
    },

    // recovery email
    recoveryEmail(state, email) {
      state.recoveryEmail.email = email
    },

    confirmRegistration(state, password) {
      state.confirmRegistration.password = password
    },

    // password recovery check
    passwordRecoveryCheck(state, passwordRecoveryCheck) {
      state.passwordRecoveryCheck = passwordRecoveryCheck
    },

    // token
    setToken(state, token) {
      state.token = token
    },

    login(state, userToken) {
      state.authStatus = true
      state.userToken = { ...userToken }
    },

    loginName(state, name) {
      state.login.name = name
    },

    loginSurname(state, surname) {
      state.login.surname = surname
    },

    loginEmail(state, email) {
      state.login.email = email
    },

    updateEditAccount(state, updateInputFileds) {
      state.login.name = updateInputFileds.name
      state.login.surname = updateInputFileds.surname
    },

    logout(state) {
      state.authStatus = ''
      state.token = '' && localStorage.removeItem('apollo-token')
    },

    setRegisterUser(state, registerUser) {
      state.registerUser = registerUser
    },

    graphqlError(state, graphqlError) {
      state.graphqlError = graphqlError
    },

    // create event - commit
    createEvent(state, createEvent) {
      state.createEvent = createEvent
    },
    
    // create event - get, set
    updateCreateEventName(state, name) {
      state.createEvent.name = name
    },

    updateCreateEventDescription(state, description) {
      state.createEvent.description = description
    },

    updateCreateEventStartsAt(state, startsAt) {
      state.createEvent.startsAt = startsAt
    },

    updateCreateEventEndsAt(state, endsAt) {
      state.createEvent.endsAt = endsAt
    },

    updateCreateEventLocation(state, location) {
      state.createEvent.location = location
    },

    updateCreateEventType(state, type) {
      state.createEvent.type = type
    },

    updateCreateEventLayout(state, layout) {
      state.createEvent.layout = layout
    },

    updateCreateEventChat(state, chat) {
      state.createEvent.chat = chat
    },

    updateCreateEventImage(state, uri) {
      state.createEvent.mainImage.uri = uri
    },

    updateCreateEventLogo(state, uri) {
      state.createEvent.logo.uri = uri
    },

    updateCreateEventTimezone(state, timezone) {
      state.createEvent.timezone = timezone
    },

    // update create image
    updateImageCreate(state, edit) {
      state.createEvent.mainImage.id = edit.id,
      state.createEvent.mainImage.uri = edit.uri
    },
    
    // edit event - commit
    editEvent(state, rspEvent) {
      state.editEvent.id = rspEvent.id
      state.editEvent.name = rspEvent.name
      state.editEvent.description = rspEvent.description
      state.editEvent.startsAt = rspEvent.liveStream.startsAt
      state.editEvent.endsAt = rspEvent.liveStream.endsAt
      state.editEvent.location = rspEvent.location
      state.editEvent.type = rspEvent.type
      state.editEvent.layout = rspEvent.layout
      state.editEvent.chat = rspEvent.chat
      state.editEvent.mainImage.uri = rspEvent.mainImage.uri
      state.editEvent.mainImage.id = rspEvent.mainImage.id
      state.editEvent.logo.id = rspEvent.logo.id
      state.editEvent.logo.uri = rspEvent.logo.uri
      //state.editEvent.timezone = editEvent.timezone
    },

    // edit event - get, set
    updateEditEventName(state, name) {
      state.editEvent.name = name
    },

    updateEditEventDescription(state, description) {
      state.editEvent.description = description
    },

    updateEditEventStartsAt(state, startsAt) {
      state.editEvent.startsAt = startsAt
    },

    updateEditEventEndsAt(state, endsAt) {
      state.editEvent.endsAt = endsAt
    },

    updateEditEventLocation(state, location) {
      state.editEvent.location = location
    },

    updateEditEventType(state, type) {
      state.editEvent.type = type
    },

    updateEditEventLayout(state, layout) {
      state.editEvent.layout = layout
    },

    updateEditEventChat(state, chat) {
      state.editEvent.chat = chat
    },

    updateEditEventTimezone(state, timezone) {
      state.editEvent.timezone = timezone
    },

    // add image from Edit.vue
    updateImageEdit(state, edit) {
      state.editEvent.mainImage.id = edit.id,
      state.editEvent.mainImage.uri = edit.uri
    },

    // add logo from Edit.vue
    updateLogoEdit(state, edit) {
      state.editEvent.logo.id = edit.id,
      state.editEvent.logo.uri = edit.uri
    },

    updateSingleTestUri(state, updateSingleTestUri) {
      state.singleEvent.liveStream.url = updateSingleTestUri
    },

    updateLive(state, live) {
      state.singleEvent.liveStream.rtmpUrl = live
    },

    // radio buttons
    updateSecurity(state, security) {
      state.security = security
    },

    updateSecurityPassword(state, securityPassword) {
      state.securityPassword = securityPassword
    },
     
    updateInviteOnly(state, inviteOnly) {
      state.inviteOnly = inviteOnly
    },

    // pagination
    limit(state, limit) {
      state.limit = limit
    },

    page(state, page) {
      state.page = page
    },

    total(state, total) {
      state.total = total
    },

    registrationSuccess(state, registrationSuccess) {
      state.registrationSuccess = registrationSuccess
    },

    registrationFail(state, registrationFail) {
      state.registrationFail = registrationFail
    },

    // confirm register
    registerSuccess(state, registerSuccess) {
      state.register.success = registerSuccess
    },

    registerError(state, registerError) {
      state.register.error = registerError
    }
  },

  actions: {
    async fetchLogin({ commit, state }) {
      return new Promise(async (resolve, reject) => {
        commit('loadingStatus', true)
        try {
          const response = await provider().clients.graphqlClient.mutate({
            mutation:
              gql`mutation Login($email:String!, $password:String!)
              {
                login(email:$email, password:$password){
                  token
                }
              }`, variables: {
              email: state.login.email,
              password: state.login.password
            }
          })
          // const token = response.data.login.token
          // commit('setToken', token)
          // localStorage.setItem('apollo-token', token)
          if (response.errors) {
            console.log(response.errors)
            //commit("registrationFail", "Your account is not active. Please check your email and activate your account")
          } else {
            const token = response.data.login.token
            commit('setToken', token)
            localStorage.setItem('apollo-token', token)
            //commit("registrationSuccess", "Registration successfull. Please check your email and activate your account")
          }
        } catch (error) {
          commit('loadingStatus', false)
          state.graphqlError = error.graphQLErrors[0].message
        }
        resolve()
      })
    },

    async fetchUser({ commit }) {
      try {
        const response = await provider().clients.graphqlClient.query({
          query: gql`
            query User{
              tokenUser{
              token
                account{
                  id
                  email
                  isAuthor,
                  name,
                  surname
                }
              }
            }
          `
        });
        commit('login', response.data.tokenUser);
      } catch (error) {
        console.log('error-message', error.graphQLErrors)
      }
    },

    async fetchLogOut({ commit }) {
      commit('logout')
    },
    
    async fetchEvents({ commit, state }, params) {
      try {
        const response = await provider().clients.graphqlClientPortal.query({
          // order:{id:{direction: DESC}}
          query: gql`
            query LiveEvents($limit: Int!, $page: Int!){
              liveEvents(pagination:{limit:$limit, page:$page}, order:{startsAt:{direction: DESC}}){
                total,
                data{
                  id,
                  name,
                  stub,
                  description,
                  mainImage{
                    id,
                    uri
                  },
                  location,
                  type,
                  layout
                  chat,
                  liveStream{
                    endsAt,
                    startsAt
                  }
                }
              }
            }
          `,
          variables: {
            limit: state.limit,
            page: state.page
          },
        });
        commit('allEvents', response.data.liveEvents.data);
        commit("total", response.data.liveEvents.total)
      } catch (error) {
        console.log('error-message', error.graphQLErrors)
      }
    },

    async fetchRegisterUser({ commit, state }) {
      return new Promise(async (resolve, reject) => {
        commit('loadingStatus', true)
        try {
          const response = await provider().clients.graphqlClient.mutate({
            mutation:
              gql`mutation RegisterUser($name:String, $surname:String,$email: String!, $password:String!, $newsletter: Boolean!, $phone: String!, $phoneCountry: String!){
              register(name: $name, surname:$surname, email:$email, password:$password, newsletter: $newsletter, phone:$phone, phoneCountry: $phoneCountry )
            }`, variables: {
              name: state.registerUser.name,
              surname: state.registerUser.surname,
              email: state.registerUser.email,
              password: state.registerUser.password,
              //newsletter: state.registerUser.newsletter,
              newsletter: true,
              phone: state.registerUser.phone,
              phoneCountry: state.registerUser.phoneCountry
            },
          })
          commit('login', response.data.tokenUser)
          commit('setRegisterUser', response.data.register)
          //commit('registrationSuccess', response.data.tokenUser)
          if (response.errors) { 
            commit("registrationFail", "Your account is not active. Please check your email and activate your account")
          } else {
            commit("registrationSuccess", "Registration successfull. Please check your email and activate your account")
          }
          // commit("registrationSuccess", "Registration successfull. Please check your email and activate your account")
          resolve()
        } catch (error) {
          commit('loadingStatus', false)
          console.log('error-message', error.graphQLErrors)
          //commit("registrationFail", "Your account is not active. Please check your email and activate your account")
          state.graphqlError = error.graphQLErrors[0].message
        }
        
      })
    },
     
    async fetchAuthUser({ commit }) {
      try {
        const response = await provider().clients.graphqlClient.query({
          query: gql`query AuthUser{
            tokenUser{
            token
              account{
                id
                email
                isAuthor,
                name,
                surname
              }
            }
          }`,
        })
        commit('login', response.data.tokenUser.token)
        commit("loginName", response.data.tokenUser.account.name)
        commit("loginSurname", response.data.tokenUser.account.surname)
        commit("loginEmail", response.data.tokenUser.account.email)
      } catch (error) {
        console.log('error-message', error.graphQLErrors)
      }
    },

    // forgot password
    async fetchForgotPassword({ commit, state }) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await provider().clients.graphqlClient.mutate({
            mutation:
              gql`mutation PasswordRecovery($email: String!, $portalId: String!){
                  passwordRecovery(email: $email, portalId: $portalId)
                }`, variables: {
              email: state.forgetEmail.email,
              portalId: process.env.VUE_APP_XPORTAL
            }
          })
          resolve(response.data.passwordRecovery)
        } catch (error) {
          console.log('error-message', error.graphQLErrors)
          state.graphqlError = error.graphQLErrors[0].message
        }
        resolve()
      })
    },

    // register
    async fetchPasswordRecoveryConfirm({ commit, state }, code) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await provider().clients.graphqlClient.mutate({
            mutation:
              gql`mutation passwordRecoveryConfirm($code:String!, $password:String!, $portalId: String!){
                passwordRecoveryConfirm(code:$code, password:$password, portalId: $portalId)
              }`, variables: {
              password: state.confirmRegistration.password,
              portalId: process.env.VUE_APP_XPORTAL,
              code: code
            }
          })
          resolve(response.data.passwordRecoveryConfirm)
            
        } catch (e) {
          console.log('error-message', e.graphQLErrors)
          state.graphqlError = e.graphQLErrors[0].message
        }
      })
    },
      
    // password recovery cehck
    async fetchPasswordRecoveryCheck({ commit, state }, code) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await provider().clients.graphqlClient.mutate({
            mutation:
              gql`mutation passwordRecoveryCheck($code: String!, $portalId: String!){
                    passwordRecoveryCheck(code:$code, portalId:$portalId)
                  }`, variables: {
              portalId: process.env.VUE_APP_XPORTAL,
              code: code,
            }
          })
          resolve(response.data.passwordRecoveryCheck)
        } catch (error) {
          console.log('error-message', error.graphQLErrors)
          state.graphqlError = error.graphQLErrors[0].message
        }
      })
    },
      
    // create event
    async fetchCreateEvent({ commit, state }) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await provider().clients.graphqlClientPortal.mutate({
            mutation:
              gql`mutation LiveEventCreate(
              $name:String!, 
              $description: String!, 
              $startsAt: String!, 
              $endsAt: String!,
              $location: String,
              $type:String!, 
              $layout:String!, 
              $chat:Boolean!,
              $mainImage:String
            ){
              liveEventCreate(input:{
                name:$name, 
                description: $description, 
                startsAt:$startsAt, 
                endsAt:$endsAt, 
                location: $location,
                type:$type, 
                layout:$layout, 
                chat:$chat,
                mainImage: $mainImage
              })
              {
                id,
                name,
                stub,
                description,
                location,
                type,
                layout,
                chat,
                mainImage{
                  id,
                  uri
                },
                liveStream{
                  startsAt,
                  endsAt,
                  rtmpUrl
                }
              }
            }`,
            variables: {
              name: state.createEvent.name,
              description: state.createEvent.description,
              startsAt: moment(state.createEvent.startsAt).format('YYYY-MM-DD HH:mm:ss').toString(),
              endsAt: moment(state.createEvent.endsAt).format('YYYY-MM-DD HH:mm:ss').toString(),
              location: state.createEvent.location,
              type: state.createEvent.type,
              layout: state.createEvent.layout,
              chat: true,
              mainImage: state.createEvent.mainImage.id.toString()
            },          
          })
          //commit("createEvent", response.data.liveEventCreate)

          console.log(response)
          if (!response.errors) {
            resolve(response.data.liveEventCreate)
          }
        } catch (err) {
          console.log(err)
        }
      })

      
    },

    // single edit event
    async fetchSingleEditEvent({ commit }, id) {
      try {
        const response = await provider().clients.graphqlClientPortal.query({
          query: gql`query LiveEvents($value: Int!){
            liveEvents(filter:{id: {value: $value, comparison: EQ}}) {
              data {
                id
                name,
                stub,
                description,
                location,
                type, 
                layout,
                chat,
                mainImage{
                  id,
                  uri
                },
                logo{
                  id,
                  uri
                },
                liveStream{
                  rtmpUrl,
                  startsAt,
                  endsAt
                }
              }
            }
          }`, variables: {
            id: parseInt(id),
            value: parseInt(id)
          },
        })
        commit('editEvent', response.data.liveEvents.data[0])
      } catch (error) {
        console.log(error)
      }
    },

    async fetchSingleEvent({ commit }, id) {
      try {
        const response = await provider().clients.graphqlClientPortal.query({
          query: gql`query LiveEvents($value: Int!){
            liveEvents(filter:{id: {value: $value, comparison: EQ}}) {
              data {
                id
                name,
                stub,
                description,
                location,
                type, 
                layout,
                chat,
                mainImage{
                  uri,
                  id
                },
                liveStream{
                  startsAt,
                  endsAt,
                  rtmpUrl,
                  url
                }
              }
            }
          }`, variables: {
            id: parseInt(id),
            value: parseInt(id),
          },
        })
        commit('singleEvent', response.data.liveEvents.data[0])
      } catch (error) {
        console.log(error)
      }
    },

    // edit event
    async fetchEditEvent({ commit, state }, id) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await provider().clients.graphqlClientPortal.mutate({
            mutation:
              gql`mutation LiveEventUpdate(
                $id:Int!, 
                $name: String!, 
                $description: String!, 
                $startsAt: String!, 
                $endsAt: String!,
                $location: String,
                $type:String!, 
                $layout:String!, 
                $chat:Boolean!,
                $mainImage:String,
								$logo:String),
                {
              liveEventUpdate(id:$id, input:{
                  name:$name,
                  description: $description,
                  startsAt: $startsAt,
                  endsAt: $endsAt,
                  location: $location,
                  type: $type,
                  layout: $layout,
                  chat: $chat,
                  mainImage:$mainImage,
                  logo:$logo
                })
                {
                  id,
                  name,
                  stub,
                  description,
                  location,
                  type,
                  layout,
                  chat,
                  logo{
                    id,
                    uri
                  }
                  mainImage{
                    uri,
                    id
                  },
                  liveStream{
                    startsAt,
                    endsAt
                  }
                }
              }
              `,
            variables: {
              id: parseInt(id),
              name: state.editEvent.name,
              description: state.editEvent.description,
              startsAt: moment(state.editEvent.startsAt).format('YYYY-MM-DD HH:mm:ss').toString(),
              endsAt: moment(state.editEvent.endsAt).format('YYYY-MM-DD HH:mm:ss').toString(),
              location: state.editEvent.location,
              type: state.editEvent.type,
              layout: state.editEvent.layout,
              chat: true,
              logo: state.editEvent.logo.id?.toString(),
              mainImage: state.editEvent.mainImage.id.toString()
            }
          })
          // boolean
          resolve(response.data.liveEventUpdate)
        } catch (err) {
          console.log(err)
        }
      })
    },

    // edit account
    async fetchEditAccount({ commit, state }) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await provider().clients.graphqlClientPortal.mutate({
            mutation:
              gql`mutation UserEdit($name: String!, $surname:String!){
                userEdit(name:$name, surname:$surname){
                  id,
                  name,
                  surname,
                  email
                }
              }`, variables: {
              name: state.login.name,
              surname: state.login.surname
            }
          })
          //resolve(response.data.userEdit)
          commit("updateEditAccount", response.data.userEdit)
        } catch (error) {
          console.log('error-message', error.graphQLErrors)
          //state.graphqlError = error.graphQLErrors[0].message
        }
        resolve()
      })
    }
  },
    
  getters: {
    getUserState: function (state) {
      return state.user;
    },

    getLoginState: function (state) {
      return state.login
    },

    isAuthenticated(state) {
      return !!state.token
    },

    authStatus(state) {
      return state.authStatus
    },

    userToken(state) {
      return state.userToken
    },
  }
});