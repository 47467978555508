<template>
  <div>
    <div class="min-h-screen flex items-center justify-center relative">
      <div class="absolute left-0 top-0 mx-auto w-full py-4">
        <div class="container px-4 mx-auto">
          <button @click="backClick" type="button"
            class="inline-flex items-center px-2.5 py-1.5 text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <svg class="mr-1 -ml-0.5 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"></path>
            </svg>
            Nazaj
          </button>
        </div>
      </div>

      <div class="container px-4 mx-auto mt-8" >
        <div class="flex flex-col lg:flex-row gap-8 py-16">
          <!-- v-if="!isVideoPlayer" -->
          <div v-if="isVideoPlayer">
            <video ref="videoPlayer" class="video-js">
              <!-- <Chat /> -->
            </video>
          </div>
          <!-- v-if="isVideoPlayer"  -->
          <div  v-if="!isVideoPlayer" class="w-full lg:w-1/2 xl:w-2/3">
            <div class="lg:relative lg:h-full">
              <img  class="w-full rounded-xl shadow-xl"
                :src="this.$store.state.singleEvent.mainImage ? this.$store.state.singleEvent.mainImage.uri : null" alt="" />
            </div>
          </div>
        </div>
   
          <div class="w-full lg:w-1/2 xl:w-1/3 flex flex-col">
            <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
              {{ this.$store.state.singleEvent.name }}
            </h2>
            <div class="flex flex-wrap space-x-4">
               <div class="text-sm mt-2 font-semibold text-gray-500 flex items-center">
                  <svg class="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  <span> {{ startsAtTest2 }} </span>
                  <!-- <span> {{ this.$store.state.singleEvent.liveStream.startsAt }} </span> -->
                </div>

              <div class="text-sm mt-2 font-semibold text-gray-500 flex items-center">
                <svg class="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path>
                    
                </svg>
                {{ this.$store.state.singleEvent.location }}
              </div>

              <div class="text-sm mt-2 font-semibold text-gray-500 flex items-center">
                <svg class="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z">
                  </path>
                </svg>
                {{ this.$store.state.singleEvent.layout }}
              </div>
            </div>

            <div class="mt-4 text-gray-400" v-html="this.$store.state.singleEvent.description"></div>
             <p>Type: {{ this.$store.state.singleEvent.type }}</p>
             <p>Chat: {{ this.$store.state.singleEvent.chat }}</p>

            <div class="mt-16 lg:mt-auto">
              <div class="flex space-x-4">
                <a href="#"
                  class="flex justify-center items-center flex-1 px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Predogled
                  <svg class="ml-2 -mr-0.5 h-4 w-4" fill="currentColor" viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z">
                    </path>
                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path>
                  </svg>
                </a>
                <router-link :to="{ name: 'Edit', params: { id: this.$route.params.id } }"
                  class="flex justify-center items-center flex-1 px-4 py-2 shadow-sm text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Uredi
                  <svg class="ml-2 -mr-0.5 h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z">
                    </path>
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                  </svg>
                </router-link>
              </div>
              <!-- v-if pri isStartBtn isVideoBought -->
              <button type="button" @click="startVideoClick"
                class="flex justify-center items-center w-full mt-4 px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                
                v-if="isStartBtn">
                Start video
                <svg class="ml-2 -mr-0.5 h-4 w-4" fill="currentColor" viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
              </button>
              <!-- <button type="button" @click="buyClick"
                class="flex justify-center items-center w-full mt-4 px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                v-else>
                Kupi - 60$
                <svg class="ml-2 -mr-0.5 h-4 w-4" fill="currentColor" viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
              </button> -->
              <p class="mt-2 text-sm text-gray-400">
                On Sale. One-time payment. Stream from any device.
              </p>
            </div>
          </div>
         
      </div>
      <div v-if="streamFinish">{{ streamFinish }}</div>
    </div>

    <!-- MODAL -->
    <card-modal v-show="isCardModalOpen" :user="user" @onCloseModal="onCloseModal"></card-modal>
    <rtmp-modal v-show="isRTMPModalOpen" :user="user" @onCloseModal="onCloseModal"></rtmp-modal>
  </div>
   
</template>

<script>
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import CardModal from "@/components/CardModal.vue";
import RTMPModal from "@/components/RTMPModal.vue";
import moment from 'moment'
import Chat from './Chat.vue'

export default {
  name: "Event",

  components: {
    CardModal,
    'rtmp-modal': RTMPModal,
    Chat
  },

  setup() {
    const buyClick = () => {
      isCardModalOpen.value = !isCardModalOpen.value
    }

    const onCloseModal = () => {
      isCardModalOpen.value = false
      isRTMPModalOpen.value = false
    }

    const user = reactive({
      email: '',
      cardNumber: '',
      cardCountry: '',
      cardAddress1: '',
      cardAddress2: '',
      cardPostalCode: '',
      cardCity: '',
      cardIsBusiness: false,
      cardVATBusinessName: '',
      cardVATBusinessNumber: ''
    })

    return {
      event,
      buyClick,
      onCloseModal,
      user
    };
  },

  data() {
     return {
      isRTMPModalOpen: false,
      isCardModalOpen:false,
      token: localStorage.getItem("apollo-token"),
      isStartBtn: true,
      isVideoPlayer: true,
      streamFinish: "",
       isVideoBought: true,
      
    }
  },

  computed: {
    startsAtTest2() {
      return moment(this.$store.state.singleEvent.liveStream.startsAt).format('DD.MM.YYYY HH:mm:ss')
    }
  },

  methods: {
    backClick() {
      this.$router.back()
    },

    liveVideo() {
      lhubPlayer.init({
        selector: this.$refs.videoPlayer,
        live: true,
        config: {
          src: this.$store.state.singleEvent.liveStream.url,
          liveConfig: {
            startsAt: moment(this.$store.state.singleEvent.endsAt).format('DD.MM.YYYY HH:mm:ss'),
            endsAt: moment(this.$store.state.singleEvent.endsAt).format('DD.MM.YYYY HH:mm:ss'),
            //timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            // startsAt: '2023-07-12 11:27:00',
            // endsAt: '2023-07-11 09:09:00',
            timezone: 'Europe/Ljubljana' 
        },
        player: {
          controls: true,
          autoplay: false,
          preferFullWindow: false,
          playsinline: true,
              controlBar: {
              children: {
              playToggle: true,
              liveDisplay: true,
                volumePanel: true,
                qualitySelector: true,
                fullscreenToggle: true,
              },
            },
          },
        },
        debug: true,
      });
    },

    onCloseModal()  {
      this.isCardModalOpen = false
      this.isRTMPModalOpen = false
    },

    startVideoClick() {
      this.isRTMPModalOpen = !this.isRTMPModalOpen;
    },
  },

  mounted() {
    this.$store.dispatch("fetchSingleEvent", this.$route.params.id)
      .then(() => {
         /* 
          Prikaži gumb start video 2h pred začetkom streama  in skrij 15min po koncu streama
          Prikazi preview video player enako kot gumb - drugače prikažeš sliko / v prihodnost pokaži player in sedanjost
          ko je 15min po koncu streama, prikazi, da je stream zaključen
        */

        let now = moment()
        let twoHoursLess = moment(this.$store.state.singleEvent.startsAt).subtract(2, 'hours')
        let endsAtAfterFifteenMinutes = moment(moment(this.$store.state.singleEvent.endsAt).add(15, 'minutes'))
        // let add15min = moment(moment(this.$store.state.singleEvent.startsAt).add(15, 'minutes').format('DD.MM.YYYY HH:mm:ss'))
        let endsAt = moment(this.$store.state.singleEvent.endsAt)

         // sedanjost startsAt < now && endsAt > now
        if (twoHoursLess < now && endsAtAfterFifteenMinutes > now) {
          this.isStartBtn = true
          this.isVideoPlayer = true
          this.liveVideo()

          // preteklost endsAt < now
          // Prikazi preview video player enako kot gumb - drugače prikažeš sliko / v prihodnost pokaži player in sedanjost
        } else if (endsAt < now) {
          this.isVideoPlayer = false
          
          // this.$store.state.singleEvent.mainImage.uri
          this.isStartBtn = false

          // prihodnost startsAt > now
        } else if (endsAtAfterFifteenMinutes > now) {
          this.liveVideo()
          this.isStartBtn = false
          this.isVideoPlayer = false
          this.streamFinish = "The stream is finished!"
        }
      })
    },
};
</script>