<template>
  <div>
    <div class="container px-4 mt-4 mx-auto">
      <div class="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          My events
        </h3>
        <div class="mt-3 flex sm:mt-0 sm:ml-4">
          <button type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Test live streaming
          </button>
          <router-link to="create">
            <button type="button"
              class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Create event
            </button>
          </router-link>
          <router-link :to="{ name: 'Account' }">
            <button type="button"
              class="ml-3 inline-flex items-center px-2 py-2 border border-indigo-400 rounded-full text-sm font-medium text-indigo-400 hover:bg-indigo-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
              </svg>
            </button>
          </router-link>
        </div>
      </div>
    </div>

    <div class="container px-4 mx-auto mt-8">
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        <div
          class="flex flex-col overflow-hidden rounded-lg shadow-lg transition transform duration-300 hover:cursor-pointer hover:-translate-y-1"
          v-for="(event, index) in this.$store.state.events " :key="index">
          <h2>{{ event.name }}</h2>
          <!-- <div class="deleteBtn" @click="deleteEvent(event.id)">
            <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clip-rule="evenodd"></path>
            </svg>
          </div> -->


          <div class="deleteBtn" @click="deleteEvent(event.id)">
                  <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clip-rule="evenodd"></path>
                  </svg>
                </div>
          <router-link :to="`/event/${event.id}/${event.stub}`">
            <div class="flex-shrink-0 relative">
              
              <img style="margin-top: 10px;" class="object-cover w-full h-48"
                :src="event.mainImage ? event.mainImage.uri : null" alt="" >
                
            </div>

            <div class="flex flex-col justify-between flex-1 p-6 bg-white">

              <div class="flex-1">
                <h4 class="text-xl font-semibold text-neutral-600">
                </h4>
                <p class="mt-1 text-sm text-gray-500" v-html="event.description"></p>
                <p>{{ event.location }}</p>
                <p>{{ event.type }}</p>
                <p>{{ event.chat }}</p>
              </div>
            </div>
          </router-link>

          <div class="flex-1 border-t border-t-gray-200">
            <div class="flex items-center flex-col sm:flex-row w-full justify-between" style="padding: 10px;">
              <div class="text-sm mb-4 sm:mb-0 font-semibold text-gray-500 flex items-center">
                <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6 mr-1" >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <span>{{ startsAtDate(event.liveStream.startsAt) }}</span>
              </div>
              <button type="button"
                class="text-sm font-semibold text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 inline-flex justify-center text-center rounded-md px-4 py-2">
                Buy
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

      <!-- <div class="center">
        <div class="pagination">
          <button class="page-link" @click="next" v-if="this.$store.state.page < numberOfPages"> Next </button>
          <div v-for="pageNumber in numberOfPages" :key="pageNumber">
            <button class="page-link"
              v-if="Math.abs(pageNumber - this.$store.state.page) < 3 || pageNumber == this.$store.state.total - 1 || pageNumber == 0"
              @click="setPage(pageNumber)"> {{ pageNumber }} </button>
          </div>
          <button class="page-link" v-if="this.$store.state.page > 1" @click="previous"> Previous </button>
        </div>
      </div> -->
 
    
    <div class="center">
      <div class="pagination">
        <vue-awesome-paginate
          :total-items=this.$store.state.total 
          v-model=this.$store.state.page
          :items-per-page=this.$store.state.limit
          :on-click="setPage(this.$store.state.page)"
          :max-pages-shown="3"
          paginate-buttons-class="btn"
          active-page-class="btn-active"
          back-button-class="back-btn"
          next-button-class="next-btn"
          prev-button-content="prev"
          next-button-content="next"
        />
      </div>
    </div>
    
    
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: "List",

  data() {
    return {
    
    }
  },

  created() {
    this.$store.dispatch("fetchEvents");
  },

  //  computed: {
  //    formattedNameDash(x) {
  //      return x.toString().toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
  //    }
  // },

  methods: {
    // eventClick(eventSlug) {
    //   this.$router.push({ name: "Event", params: { id: eventSlug } });
    // }

    // deleteEvent(id) {
    // this.$store.commit("deleteEvent", id);
    // this.$store.dispatch("fetchDeleteEvent", this.$route.params.id)
    // .then(() =>this.$store.commit("deleteEvent", id))
    // .then(() => this.$router.push('/list'))
    // },

    setPage(x) {
      this.$store.commit("page", x)
      this.$store.dispatch("fetchEvents")
      this.$router.push({ path: '/list', query: { page: this.$store.state.page } })
      this.$route.query
    },

    // previous() {
    //   this.$store.commit("page", this.$store.state.page = this.$store.state.page - 1)
    //   this.$store.dispatch("fetchEvents")
    // },

    // next() {
    //   this.$store.commit("page", this.$store.state.page = this.$store.state.page + 1)
    //   this.$store.dispatch("fetchEvents")
    // },

    startsAtDate(starsAt) {
      return moment(starsAt).format('DD.MM.YYYY HH:mm:ss')
    },
  }
}
</script>

<style>
.center {
  margin-top: 50px;
  margin-bottom: 40px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  border-radius: 5px;
}

.btn {
    height: 40px;
    width: 40px;
    border: none;
    margin-inline: 2px;
    cursor: pointer;
  }

  .back-btn {
    background-color:  #eae9fc;
    border-radius: 7px;
    width: 70px;
  }

  .next-btn {
    background-color: #eae9fc;  
    border-radius: 7px;
    width: 70px;
  }

  .btn-active {
    background-color: #4f46e5;
    color: #fff;
    border-radius: 7px;
  }

  .deleteBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #f87171;
    color: #fff;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top:45px;
    z-index: 1;
  }
</style>
