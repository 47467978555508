import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createApolloProvider } from '@vue/apollo-option';
import { onError } from '@apollo/client/link/error';
import { ApolloLink, concat } from "apollo-link";

onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  
  if (networkError) {
    networkError.message = networkError.result.errors[0].debugMessage
    console.log(`[Network error]: ${networkError}`)
  };
});

const XPORTAL = process.env.VUE_APP_XPORTAL
let GRAPHQLPORTAL = process.env.VUE_APP_API_GRAPHQLPORTAL
let GRAPHQL = process.env.VUE_APP_API_GRAPHQL

const httpLink = new HttpLink({ uri: GRAPHQLPORTAL });

const authMiddleware = new ApolloLink((operation, forward) => {
const token = localStorage.getItem('apollo-token');
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      "x-portal" : XPORTAL,
      "X-Requested-With" :"XMLHttpRequest"
    },
  });
  return forward(operation);
});
export const graphqlClientPortal = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache()
});

const httpLink2 = new HttpLink({ uri: GRAPHQL });

const authMiddleware2 = new ApolloLink((operation, forward) => {
const token = localStorage.getItem('apollo-token');
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      "x-portal" : XPORTAL,
      "X-Requested-With" :"XMLHttpRequest"
    },
  });
  return forward(operation);
});
export const graphqlClient = new ApolloClient({
  link: concat(authMiddleware2, httpLink2),
  cache: new InMemoryCache()
});

// const token = window.localStorage.getItem("apollo-token");

// const authLink = setContext(async(_, { headers }) => {
//   return {
//     headers: {
//       ...headers,
//         authorization: token ? `Bearer ${token}` : "",
//         "x-portal" : XPORTAL,
//         "X-Requested-With" :XMLHttpRequest
//     },
//   }
// })

// function getHeaders() {
//   const headers = {};

//   const token = window.localStorage.getItem("apollo-token");
//   if (token) {
//     headers["Authorization"] = `Bearer ${token}`;
//   }
//   headers["x-portal"] = XPORTAL;
//   headers["X-Requested-With"] = "XMLHttpRequest";

//   return headers
// }

// const graphqlClient = new ApolloClient({
//   link: new HttpLink({uri: GRAPHQL, headers: getHeaders()}),
//   cache: new InMemoryCache()
// });

// const graphqlClientPortal = new ApolloClient({
//   link: new HttpLink({ uri: GRAPHQLPORTAL, headers: getHeaders()}),
//   cache: new InMemoryCache()
// });

export function provider() {
  const provider = createApolloProvider({
      clients: {
        graphqlClient,
        graphqlClientPortal
      },
      defaultClient: graphqlClient,
      defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network'
      },
        
      // pagination
      // nextFetchPolicy: 'cache-first'
    },
  })
  return provider
}