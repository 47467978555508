<template>
  <div class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-50">
    <div class="max-w-md w-full space-y-8">
      <div>
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          or
          <router-link :to="{ name: 'Register' }" class="font-medium text-indigo-600 hover:text-indigo-500">
            register if you don't already have an account
          </router-link>
        </p>
      </div>
      <Form ref="myForm" class="mt-8 space-y-6" action="#" method="POST">
        <Field type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email-address" class="sr-only">E-mail</label>
            <Field id="email-address" name="email" :rules="validateEmail" type="email" v-model="email"
              autocomplete="email" required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="E-mail" />
            <ErrorMessage class="warning" name="email" />
            <div class="warning" v-if="this.$store.state.login.error">Error: {{ this.$store.state.login.error }}</div>
          </div>
          <div>
            <label for="password" class="sr-only">Password</label>
            <Field id="password" v-model="password" name="password" type="password" autocomplete="current-password"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Password" />
            <ErrorMessage class="warning" name="password" />
            <div class="warning" v-if="this.$store.state.login.error">Error: {{ this.$store.state.login.error }}</div>
          </div>
          <div v-if="passwordMissmatch">{{ passwordMissmatch }}</div>
        </div>

        <div class="flex items-center justify-between">
          <div class="text-sm">
            <router-link :to="{ name: 'ForgotPassword' }" class="font-medium text-indigo-600 hover:text-indigo-500">
              Forgot your password?
            </router-link>
          </div>
        </div>

        <div>
          <button type="submit" :disabled="!disableButton" @click.prevent="submitForm"
            class=" disabled group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd" />
              </svg>
            </span>
            Sign in
          </button>
        </div>
        <div v-if="loginError" class="center">{{ loginError }}</div>
      </Form>
      <div class="flex items-center justify-center" v-if="this.$store.state.graphqlError">{{ this.$store.state.graphqlError}}</div>
      <div class="flex items-center justify-center" v-if="this.$store.state.registrationSuccess">{{ this.$store.state.registrationSuccess }}</div>
      <div class="flex items-center justify-center" v-if="this.$store.state.registrationFail">{{ this.$store.state.registrationFail }}</div>
      <Loader />
  
    </div>

  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import Loader from "../components/Loader.vue";

export default {
  name: "SignIn",

  data() {
    return {
      passwordMissmatch: "",
      loginError: ""
    }
  },

  components: {
    Form,
    Field,
    ErrorMessage,
    Loader
  },

  methods: {
    submitForm(values) {
      this.$store.dispatch("fetchLogin")
        .then(() => this.$router.push('/list'))
        .then(() => this.$store.commit("loadingStatus", false))
        .then(() => this.loginError = "E-mail or password is incorrect")
    },

    validateEmail(value) {
      if (!value) {
        return 'This field is required'
      }

      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,5}$/i;

      if (!regex.test(value)) {
        return 'This field must be a valid email';
      }

      return true;
    }
  },

  computed: {
    email: {
      get() {
        return this.$store.state.login.email
      },

      set(value) {
        this.$store.commit('updateEmailLogin', value)
      }
    },

    password: {
      get() {
        return this.$store.state.login.password
      },

      set(value) {
        this.$store.commit('updatePasswordLogin', value)
      }
    },

    disableButton() {
      return this.$store.state.login.email != '' && this.$store.state.login.password != ''
    }
  }
}
</script>

<style scoped>
.warning {
  color: red;
}

.disabled:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.center {
  text-align: center;
}</style>
