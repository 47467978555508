<template>
  <div class="pb-8">
    <div class="container mx-auto px-4 mt-4">
      <div class="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Edit event
        </h3>
        <div class="mt-3 flex sm:mt-0 sm:ml-4">
          <button type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="cancelCreation">
            Cancel
          </button>
          <button type="button"
            class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="saveEvent">
            Save
          </button>
        </div>
      </div>
    </div>
    <div class="container mx-auto px-4 mt-8">
      <div class="max-w-xl mx-auto">
        <Form>
          <div class="space-y-8 divide-y divide-gray-200">
            <div>
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Event
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  Publicly available information
                </p>
              </div>

              <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div class="sm:col-span-6">
                  <label for="event-name" class="block text-sm font-medium text-gray-700">
                    Event name
                  </label>
                  <div class="mt-1">
                    <Field v-model="name" type="text" name="event-name" id="event-name"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                  </div>
                </div>

                <div class="sm:col-span-6">
                  <label for="event-description" class="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <div class="mt-1">
                    <Field as="textarea" style="resize: none;" v-model="description" type="text" name="event-description"
                      id="event-description"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                  </div>
                </div>

                <div class="sm:col-span-6" @dragenter="OnDragEnter" @dragleave="OnDragLeave" @dragover.prevent
                  @drop="OnDrop">

                  <label for="cover-photo" class="block text-sm font-medium text-gray-700">
                    Cover photo
                  </label>
                  <div v-if="!this.$store.state.editEvent.mainImage.id" :class="{ dragging: isDragging }"
                    class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                    <div class="space-y-1 text-center">
                      <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48"
                        aria-hidden="true">
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>

                      <div class="flex text-sm text-gray-600">
                        <div>
                          <label for="file-upload"
                            class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">

                            <p>Upload a file</p>
                            <Field id="file-upload" name="file-upload" type="file" class="sr-only"
                              @change="changeImage($event)" accept=".png, .jpg, .gif" />
                          </label>

                          <p class="pl-1">or drag and drop</p>
                          <Field id="file-upload" name="file-upload" type="file" class="sr-only"
                            accept=".png, .jpg, .gif" />

                          <p class="text-xs text-gray-500">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="divRemove">
                    <div>
                      <img v-if="this.$store.state.editEvent.mainImage.uri"
                        :src="this.$store.state.editEvent.mainImage.uri" />
                      <div>
                        <button v-if="this.$store.state.editEvent.mainImage.id" class="buttonRemove"
                          @click.prevent="removeBtnImages()"><i class="fa-regular fa-trash-can"></i></button>
                      </div>
                    </div>
                  </div>

                  <div>
                    <p v-if="notImage" class="warning" style="text-align: center;">{{ notImage }}</p>
                    <p v-if="successMessage" class="warning" style="text-align: center;">{{ successMessage }}</p>
                    <p v-if="errorMessage" class="warning" style="text-align: center;">{{ errorMessage }}</p>
                    <p v-if="tooLarge" class="warning" style="text-align: center;">{{ tooLarge }}</p>
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Event start (Date and time)
                  </label>
                  <div class="mt-1">
                    <VueDatePicker v-model="startsAt" locale="sl" cancelText="Zapri" selectText="Izberi">
                      <template #dp-Field="{ value }">
                        <Field type="text" :value="value"
                          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                      </template>
                    </VueDatePicker>
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label class="block text-sm font-medium text-gray-700">
                    Event end (Date and time)
                  </label>
                  <div class="mt-1">
                    <VueDatePicker v-model="endsAt" locale="sl" cancelText="Zapri" selectText="Izberi">
                      <template #dp-Field="{ value }">
                        <Field type="text" :value="value"
                          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                      </template>
                    </VueDatePicker>
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label for="event-location" class="block text-sm font-medium text-gray-700">
                    Location
                  </label>
                  <div class="mt-1">
                    <Field v-model="location" type="text" name="event-location" id="event-location"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                  </div>
                </div>  

                <div class="sm:col-span-3">
                  <label for="timezone" class="block text-sm font-medium text-gray-700">Time zone</label>
                  <Field type="text" id="timezone" name="timezone"
                    class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    v-model="timezone" />
                </div>

                <div class="sm:col-span-3">
                  <label for="event-type" class="block text-sm font-medium text-gray-700">Event type</label>
                  <select id="event-type" name="event-type"
                    class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    v-model="type">
                    <option v-for="eventType in eventTypes" :key="eventType.value" :value="eventType.value">
                      {{ eventType.text }}
                    </option>
                  </select>
                </div>

                <div class="sm:col-span-3" @dragenter="OnDragEnter" @dragleave="OnDragLeave" @dragover.prevent
                    @drop="OnDropLogo">
                  <label for="logo" class="block text-sm font-medium text-gray-700">
                    Logotype
                  </label>
                  <div v-if="!this.$store.state.editEvent.logo.id" :class="{ dragging: isDragging }" class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                    <div class="space-y-1 text-center">
                      <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48"
                        aria-hidden="true">
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <div class="flex text-sm text-gray-600">
                        <label for="logo"
                          class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                          <span>Upload a file</span>
                          <Field id="logo" name="logo" type="file" class="sr-only" @change="changeImageLogo($event)" accept=".png, .jpg, .gif" />
                        </label>
                        <p class="pl-1">or drag and drop</p>
                        <Field id="logo" name="logo" type="file" class="sr-only" accept=".png, .jpg, .gif" />
                      </div>
                      <p class="text-xs text-gray-500">
                        PNG, JPG, GIF up to 10MB
                      </p>
                    </div>
                  </div>
                  <div class="divRemove">
                        <div>
                          <img v-if="this.$store.state.editEvent.logo.uri" :src="this.$store.state.editEvent.logo.uri" />
                          <div>
                            <button v-if="this.$store.state.editEvent.logo.id" class="buttonRemove"
                              @click.prevent="removeBtnImagesLogo()"><i class="fa-regular fa-trash-can"></i></button>
                          </div>
                        </div>
                      </div>
                   <div>
                      <p v-if="notImageLogo" class="warning" style="text-align: center;">{{ notImageLogo }}</p>
                      <p v-if="successMessageLogo" class="warning" style="text-align: center;">{{ successMessageLogo }}</p>
                      <p v-if="errorMessageLogo" class="warning" style="text-align: center;">{{ errorMessageLogo }}</p>
                      <p v-if="tooLargeLogo" class="warning" style="text-align: center;">{{ tooLargeLogo }}</p>
                    </div>
                </div>            

                <div class="sm:col-span-6">
                  <label for="layout-selector" class="block text-sm font-medium text-gray-700">Select design</label>
                  <select id="layout-selector" name="layout-selector"
                    class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    v-model="layout">
                    <option v-for="eventLayout in eventLayouts" :key="eventLayout.value" :value="eventLayout.value">
                      {{ eventLayout.text }}
                    </option>
                  </select>
                </div>

                <div class="sm:col-span-6">
                    <label for="last-name" class="block text-sm font-medium text-gray-700">
                      Privacy
                    </label>
                    <div class="mt-4 space-y-4">
                      <div class="flex items-center">
                        <Field id="direct-link-protected" v-model="security" value="direct-link-protected" 
                          name="event-security" type="radio"
                          class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                        <label for="direct-link-protected" class="ml-3 block text-sm font-medium text-gray-700">
                          Only people with the link can watch
                        </label>
                      </div>

                      <div class="flex items-center">
                        <Field id="password-protected" v-model="security" value="password-protected" name="event-security" 
                          type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                        <label for="password-protected" class="ml-3 block text-sm font-medium text-gray-700">
                          Password protected
                        </label>
                      </div>

                      <div class="mt-1" v-if="security === 'password-protected'">
                        <Field type="text" v-model="securityPassword" name="security-password" id="security-password" 
                          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                      </div>

                      <div class="flex items-center">
                          <Field id="invite-only" v-model="inviteOnly" value="invite-only"
                            name="event-security" type="radio"
                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                          <ErrorMessage class="warning" name="event-security" />
                          <label for="invite-only" class="ml-3 block text-sm font-medium text-gray-700">
                            Invite only
                          </label>
                      </div>

                    </div>
                  </div>

                <div class="sm:col-span-6">
                  <label for="enable-chat" class="block text-sm font-medium text-gray-700">
                    Chat
                  </label>
                  <div class="flex items-start mt-1">
                    <div class="h-5 flex items-center">
                      <Field v-model="chat" id="enable-chat" name="enable-chat" type="checkbox"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="enable-chat" class="font-medium text-gray-700">Enable chat</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>

        <div class="mt-12 ml-auto sm:flex sm:items-center sm:justify-end">
          <div class="flex">
            <button type="button"
              class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="cancelCreation">
              Prekini
            </button>
            <button type="button" :disabled="!disableButton"
              class="disabled ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="saveEvent">
              Shrani
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import eventTypes from "@/_common/eventTypes";
import { Form, Field, ErrorMessage } from 'vee-validate';
import axios from 'axios';

export default {
  name: "Create",

  components: {
    VueDatePicker,
    Form,
    Field,
    ErrorMessage,
    eventTypes
  },

  setup() {
     return {
     
      eventTypes,
      
    };

  },

  computed: {
    name: {
      get() {
        return this.$store.state.editEvent.name
      },

      set(value) {
        this.$store.commit('updateEditEventName', value)
      }
    },

    description: {
      get() {
        return this.$store.state.editEvent.description
      },
      set(value) {
        this.$store.commit('updateEditEventDescription', value)
      }
    },

    startsAt: {
      get() {
        return this.$store.state.editEvent.startsAt
      },

      set(value) {
        this.$store.commit('updateEditEventStartsAt', value)
      }
    },

    endsAt: {
      get() {
        return this.$store.state.editEvent.endsAt
      },
      // set(value) {
      //   if (value) {
      //     return value = this.formatted
      //   }
      //   this.$store.commit('updateEditEventEndsAt', value)
      // }
      set(value) {
        this.$store.commit('updateEditEventEndsAt', value)
      }
    },

    location: {
      get() {
        return this.$store.state.editEvent.location
      },

      set(value) {
        console.log(value)
        this.$store.commit('updateEditEventLocation', value)
      }
    },

    type: {
      get() {
        return this.$store.state.editEvent.type
      },
      set(value) {
        this.$store.commit('updateEditEventType', value)
      }
    },

    layout: {
      get() {
        return this.$store.state.editEvent.layout
      },
      set(value) {
        this.$store.commit('updateEditEventLayout', value)
      }
    },

    chat: {
      get() {
        return this.$store.state.editEvent.chat
      },

      set(value) {
        if (!value) {
          value = false
        }
       
        this.$store.commit('updateEditEventChat', value)
      }
    },

    security: {
      get() {
        return this.$store.state.security
      },
      set(value) {
        console.log(value)
        this.$store.commit('updateSecurity', value)
      }
    },

    securityPassword: {
      get() {
        return this.$store.state.securityPassword
      },
      set(value) {
        console.log(value)
        this.$store.commit('updateSecurityPassword', value)
      }
    },

    inviteOnly: {
      get() {
        return this.$store.state.inviteOnly
      },
      set(value) {
        console.log(value)
        this.$store.commit('updateInviteOnly', value)
      }
    },

    timezone: {
      get() {
        return this.$store.state.editEvent.timezone
      },
      set(value) {
        this.$store.commit('updateEditEventTimezone', value)
      }
    },

    disableButton() {
      return this.$store.state.editEvent.name != '' && this.$store.state.editEvent.startsAt != '' && this.$store.state.editEvent.endsAt != '' && this.$store.state.editEvent.location != ''
    }
  },

  data() {
    return {
      // eventTypes: [
      //   {
      //     text: 'Wedding',
      //     value: 'wedding'
      //   },
      //   {
      //     text: 'Baptism',
      //     value: 'baptism'
      //   },
      //   {
      //     text: 'Birthday',
      //     value: 'birthday'
      //   },
      //   {
      //     text: 'Funeral',
      //     value: 'funeral'
      //   },
      //   {
      //     text: 'Other',
      //     value: 'other'
      //   }
      // ],
      // eventLayouts: [
      //   {
      //     text: 'Design 1',
      //     value: 'layout-1'
      //   },
      //   {
      //     text: 'Design 2',
      //     value: 'layout-2'
      //   },
      //   {
      //     text: 'Design 3',
      //     value: 'layout-3'
      //   },
      // ],
      url: "",
      seen: true,
      deleteBtn: false,
      isDragging: false,
      dragCount: 0,
      image: "",
      previewImage: "",
      notImage: "",
      successMessage: "",
      errorMessage: "",
      tooLarge: "",
      isHidden: false,
      apiUrl: process.env.VUE_APP_API_URL,
      xPortal: process.env.VUE_APP_XPORTAL,
      imageLogo: "",
      notImageLogo:"",
      successMessageLogo:"",
      errorMessageLogo:"",
      tooLargeLogo: "",
      seenLogo: true,
      deleteBtnLogo: false,
    }
  },

  methods: {
    saveEvent() {
      this.$store.dispatch("fetchEditEvent", this.$route.params.id).then(() => this.$router.push('/list'))
    },

    cancelCreation() {
      this.$router.back();
    },

    changeImage(event) {
      if (event.target.files[0].size / (1024 * 1024) > 10) {
        this.tooLarge = "File size is too large (max 10 MB)";
        return;
      }

      this.image = event.target.files[0];

      if (!this.image.type.match('image.*')) {
        this.notImage = "File is not an image"
        return
      }

      const fd = new FormData();
      fd.append("file", this.image)

      const token = window.localStorage.getItem("apollo-token");
      const headers = {
        'Authorization': `Bearer ${token}`,
        'x-portal': this.xPortal,
        'Content-Type': 'multipart/form-data'
      };
      axios.post(this.apiUrl, fd, { headers })
        .then(res => {
          this.url = {
            id: res.data.id, uri: res.data.location
          }
          this.$store.commit("updateImageEdit", { id: res.data.id, uri: res.data.location })
        })
        .then(
          this.seen = false,
          this.deleteBtn = true
        )
        .then(() => {
          // this.successMessage = "Image has been sent"
          console.log('Success!');
        })
        .catch(err => {
          //  this.errorMessage = "Image has not been sent"
          console.log(err);
        })
    },

    changeImageLogo(event) {
      if (event.target.files[0].size / (1024 * 1024) > 10) {
        this.tooLargeLogo = "File size is too large (max 10 MB)";
        return;
      }

      this.imageLogo = event.target.files[0];

      if (!this.imageLogo.type.match('image.*')) {
        this.notImageLogo = "File is not an image"
        return
      }

      const fd = new FormData();
      fd.append("file", this.imageLogo)

      const token = window.localStorage.getItem("apollo-token");
      const headers = {
        'Authorization': `Bearer ${token}`,
        'x-portal': this.xPortal,
        'Content-Type': 'multipart/form-data'
      };
      axios.post(this.apiUrl, fd, { headers })
        .then(res => {
          this.url = {
            id: res.data.id, uri: res.data.location
          }
          this.$store.commit("updateLogoEdit", { id: res.data.id, uri: res.data.location })
        })
        .then(
          this.seenLogo = false,
          this.deleteBtnLogo = true
        )
        .then(() => {
          // this.successMessage = "Image has been sent"
          console.log('Success!');
        })
        .catch(err => {
          //  this.errorMessage = "Image has not been sent"
          console.log(err);
        })
    },

    OnDragEnter(e) {
      e.preventDefault();
      this.dragCount++
      this.isDragging = true
    },

    OnDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) {
        this.isDragging = false
      }
    },

    OnDrop(e) {
      e.preventDefault();
      e.stopPropagation();

      if (e.dataTransfer.files[0].size / (1024 * 1024) > 10) {
        this.tooLarge = "File size is too large (max 10 MB)";
        return;
      }
      this.image = e.dataTransfer.files[0]

      if (!this.image.type.match('image.*')) {
        this.notImage = "File is not an image"
        return
      }

      const fd = new FormData();
      fd.append("file", this.image)

      const token = window.localStorage.getItem("apollo-token");
      const headers = {
        'Authorization': `Bearer ${token}`,
        'x-portal': this.xPortal,
        'Content-Type': 'multipart/form-data'
      };
      axios.post(this.apiUrl, fd, { headers })
        .then(res => {
          this.url = {
            id: res.data.id, uri: res.data.location
          }
          this.$store.commit("updateImageEdit", { id: res.data.id, uri: res.data.location })
        })
        .then(
          this.seen = false,
          this.deleteBtn = true
        )
        .then(() => {
          //this.successMessage = "Image has been sent"
          console.log('Success!');
        })
        .catch((err) => {
          //this.errorMessage = "Image has not been sent"
          console.log(err);
        })

      this.isDragging = false;
    },

    OnDropLogo(e) {
      e.preventDefault();
      e.stopPropagation();

      if (e.dataTransfer.files[0].size / (1024 * 1024) > 10) {
        this.tooLargeLogo = "File size is too large (max 10 MB)";
        return;
      }
      this.imageLogo = e.dataTransfer.files[0]

      if (!this.imageLogo.type.match('image.*')) {
        this.notImageLogo = "File is not an image"
        return
      }

      const fd = new FormData();
      fd.append("file", this.imageLogo)

      const token = window.localStorage.getItem("apollo-token");
      const headers = {
        'Authorization': `Bearer ${token}`,
        'x-portal': this.xPortal,
        'Content-Type': 'multipart/form-data'
      };
      axios.post(this.apiUrl, fd, { headers })
        .then(res => {
          this.url = {
            id: res.data.id, uri: res.data.location
          }
          this.$store.commit("updateLogoEdit", { id: res.data.id, uri: res.data.location })
        })
        .then(
          this.seenLogo = false,
          this.deleteBtnLogo = true
        )
        .then(() => {
          //this.successMessage = "Image has been sent"
          console.log('Success!');
        })
        .catch((err) => {
          //this.errorMessage = "Image has not been sent"
          console.log(err);
        })

      this.isDragging = false;
    },

    removeBtnImages() {
      this.successMessage = ""
      this.errorMessage = ""
      this.tooLarge = ""
      this.$store.commit("updateImageEdit", { id: "", uri: "" })
    },

    removeBtnImagesLogo() {
      this.successMessageLogo = ""
      this.errorMessageLogo = ""
      this.tooLargeLogo = ""
      this.$store.commit("updateLogoEdit", { id: "", uri: "" })
    }
  },

  mounted() {
    this.$store.dispatch("fetchSingleEditEvent", this.$route.params.id)
  }
}
</script>

<style scoped>
.warning {
  color: red;
}

.divRemove {
  position: relative;
}

.buttonRemove {
  position: absolute;
  top: 15px;
  right: 15px;
  color: white;
  font-size: 15px;
}

img {
  width: 100%;
  height: 170px;
  object-fit: cover;
  object-position: center;
}

i {
  width: 30px;
  height: 30px;
  border: 1px solid #d1d5db;
  border-radius: 50%;
  background: #d1d5db;
  line-height: 27px;
}

.dragging {
  background: #eae9fc;
  border: 1px dashed #4f46e5;
}

.disabled:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
</style>
