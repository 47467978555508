<template>
        <div class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-50">
            <div class="max-w-md w-full space-y-8">
                <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Password Recovery
                </h2>
                <Form class="mt-8 space-y-6" action="#" method="POST" v-if="codeCheck">
                    <div class="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label for="password" class="sr-only">Password</label>
                            <Field id="password" v-model="password" :rules="validatePassword" name="password" type="password"
                                autocomplete="new-password" required
                                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Password" />
                            <ErrorMessage class="warning" name="email" />
                            <div class="warning" v-if="this.$store.state.registerUser.error">
                            Error: {{ this.$store.state.registerUser.error }}</div>
                        </div>
                        <div>
                            <label for="password-confirmation" class="sr-only">Confirm password</label>
                            <Field id="password-confirmation" v-model="passwordConfirmation" :rules="validatePassword"
                                name="password-confirmation" type="password" autocomplete="new-password" required
                                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Confirm password" />
                            <ErrorMessage class="warning" name="email" />
                            <div class="warning" v-if="this.$store.state.registerUser.error">
                                Error: {{ this.$store.state.registerUser.error }}</div>
                        </div>
                        <div>
                            <button type="submit" @click.prevent="passwordRecovery" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Password Recovery
                            </button>
                            <div v-if="this.$store.state.graphqlError">{{ this.$store.state.graphqlError }}</div>
                        </div>

                        <button @click="nazaj">Nazaj</button>
                    </div>
                </Form>
                <div v-else>Your code is not valid.</div>
            </div>
        </div>
</template>


<script>
import { Form, Field, ErrorMessage } from 'vee-validate';


export default {

    components: {
        Form,
        Field,
        ErrorMessage
    },


    data() {
        return {
            codeCheck:false
        }
    },

      methods: {
          passwordRecovery() {
            //password reset
              this.$store.dispatch("fetchPasswordRecoveryConfirm", this.$route.params.code).then(res => {
                this.Field = ""
              }).then(() => this.$router.push('/login')) 
        },

        validatePassword(value) {
            if (!value) {
                return 'This filed is required'
            }

            const regex = /"^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"/;
            if (!regex.test(value)) {
                return 'Password must has minimum eight characters, at least one letter and one number';
            }

            return true;
          },

          nazaj() {
            this.$router.back()
        }
    },

    computed: {
        password: {
            get() {
                return this.$store.state.registerUser.password
            },
            set(value) {
                this.$store.commit('updateRegisterUserPassword', value)
            }
        },

        passwordConfirmation: {
            get() {
                return this.$store.state.registerUser.passwordConfirmation
            },
            set(value) {
                this.$store.commit('updateRegisterUserpasswordConfirmation', value)
            }
        }
    },

    beforeCreate() {
        this.$store.dispatch("fetchPasswordRecoveryCheck", this.$route.params.code).then((res) => {
            this.codeCheck = res
        })
    }
}
</script>

<style scoped>
.warning {
    color: red;
}
</style>