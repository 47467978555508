<template>
  <div class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-50">
    <div class="max-w-md w-full space-y-8">
      <div>
        <!-- <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow"> -->
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Forgot your password?
        </h2>
        <h4 class="mt-6 text-center text-xl  text-gray-900">
        Enter your email address that you used to register. 
        </h4>
        <p class="mt-2 text-center text-sm text-gray-600">
          <router-link :to="{ name: 'Register' }" class="font-medium text-indigo-600 hover:text-indigo-500">
            Or register if you don't have an account yet
          </router-link>
        </p>
      </div>
      <Form class="mt-8 space-y-6" action="#" method="POST">
        <input type="hidden" name="remember" value="true">
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email-address" class="sr-only">E-email</label>
            <Field id="email-address" :rules="validateEmail" v-model="email" name="email" type="email" autocomplete="email" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="E-email" />
            <ErrorMessage class="warning" name="email" />
            <div class="warning" v-if="this.$store.state.registerUser.error">Error: {{ this.$store.state.registerUser.error }}</div>
          </div>
        </div>

        <!-- <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
            <label for="remember-me" class="ml-2 block text-sm text-gray-900">
              Remember me
            </label>
          </div>

          <div class="text-sm">
            <router-link :to="{ name: 'ForgotPassword' }" class="font-medium text-indigo-600 hover:text-indigo-500">
              Pozabljeno geslo
            </router-link>
          </div>
        </div> -->

        <div>
          <button type="submit" @click.prevent="passwordResetSubmit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
              </svg>
            </span>
            Send password reset link
          </button>
          <div v-if="errors">{{ errors }}</div>
          <div v-if="msg">{{ msg }}</div>
          <div v-if="this.$store.state.graphqlError">{{ this.$store.state.graphqlError }}</div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';

export default {
  name: "ForgotPassword",

  components: {
    Form,
    Field,
    ErrorMessage
  },

  data() {
    return {
      msg: "",
      errors:""
    }
  },

  methods: {
    validateEmail(value) {
      if (!value) {
        return 'This filed is required'
      }

      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,5}$/i;

      if (!regex.test(value)) {
        return 'This field must be a valid email';
      }

      return true;
    },

    passwordResetSubmit() {
      this.$store.dispatch("fetchForgotPassword").then(res => {
        this.msg = "Please check your email"
        this.Field = ""
      }).catch(err => {
        this.errors = "Error, please try again."
      })
    }
  },

  computed: {
      // binding inputs with v-model in Form
      email: {
        get() {
          return this.$store.state.forgetEmail.email
        },

        set(value) {
          this.$store.commit('updateForgetEmail', value)
        }
      },
    }
}
</script>